/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import _ from 'lodash';
import {
  obtenerTorneos, editarTorneo, crearTorneo, eliminarTorneo,
} from '../../services/torneo';
import actions from './actions';

export function* LOAD_TORNEOS() {
  yield put({
    type: 'torneos/SET_STATE',
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(obtenerTorneos);

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'torneos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export function* EDITAR_TORNEO(torneo) {
  try {
    const response = yield call(editarTorneo(torneo.payload));

    if (response) {
      const { data: entities } = response;

      message.destroy();
      message.success('Guardado correctamente');

      yield put({
        type: 'torneos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* CREAR_TORNEO(torneo) {
  try {
    const response = yield call(crearTorneo(torneo.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'torneos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
      const newPatientId = entities
        .find((pac) => {
          return _.get(pac, 'nombre', 'x') === _.get(torneo, 'payload.nombre', 'z') && _.get(pac, 'email', 'x') === _.get(torneo, 'payload.email', 'z');
        }).id;
      window.open(`/torneo/${newPatientId}/data`, '_top');
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* ELIMINAR_TORNEO(torneo) {
  try {
    const response = yield call(eliminarTorneo(torneo.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'torneos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_TORNEOS, LOAD_TORNEOS),
    takeEvery(actions.EDITAR_TORNEO, EDITAR_TORNEO),
    takeEvery(actions.CREAR_TORNEO, CREAR_TORNEO),
    takeEvery(actions.ELIMINAR_TORNEO, ELIMINAR_TORNEO),
    LOAD_TORNEOS(), // run once on app load to check user auth
  ]);
}
