/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  obtenerSesionDeMedicions, editarSesionDeMedicion, crearSesionDeMedicion, eliminarSesionDeMedicion,
} from '../../services/sesion-de-medicion';
import actions from './actions';

export function* LOAD_SESIONDEMEDICIONS() {
  yield put({
    type: 'sesiondemedicions/SET_STATE',
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(obtenerSesionDeMedicions);

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'sesiondemedicions/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export function* EDITAR_SESIONDEMEDICION(sesiondemedicion) {
  try {
    const response = yield call(editarSesionDeMedicion(sesiondemedicion.payload));

    if (response) {
      const { data: entities } = response;

      message.destroy();
      message.success('Guardado correctamente');

      yield put({
        type: 'sesiondemedicions/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* CREAR_SESIONDEMEDICION(sesiondemedicion) {
  try {
    const response = yield call(crearSesionDeMedicion(sesiondemedicion.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'sesiondemedicions/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* ELIMINAR_SESIONDEMEDICION(sesiondemedicion) {
  try {
    const response = yield call(eliminarSesionDeMedicion(sesiondemedicion.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'sesiondemedicions/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SESIONDEMEDICIONS, LOAD_SESIONDEMEDICIONS),
    takeEvery(actions.EDITAR_SESIONDEMEDICIONS, EDITAR_SESIONDEMEDICION),
    takeEvery(actions.CREAR_SESIONDEMEDICIONS, CREAR_SESIONDEMEDICION),
    takeEvery(actions.ELIMINAR_SESIONDEMEDICIONS, ELIMINAR_SESIONDEMEDICION),
    LOAD_SESIONDEMEDICIONS(), // run once on app load to check user auth
  ]);
}
