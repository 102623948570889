import React from 'react';
import numeral from 'numeral';

function PriceCardMonthly({ price }) {
  return (
    <div className="plan__price">
      <h2>{numeral(price).format('$ 0,0[.]00')} MXN</h2>
      <span>Por mes</span>
    </div>
  );
}

PriceCardMonthly.defaultProps = {
  price: 10,
};

export default PriceCardMonthly;
