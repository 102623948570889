import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import styles from './style.module.scss';

const Footer = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.inner}>
        <div className="row">
          <div className="col-lg-9">
            <p>
              <strong><FormattedHTMLMessage id="Sistema MP - Mas poder" /></strong>
            </p>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className="row">
            <div className="col-sm-6" />
            <div className="col-sm-6">
              <div className={styles.copyright}>
                <img
                  src="resources/images/mediatec.png"
                  rel="noopener noreferrer"
                  alt="Sistema MP"
                />
                <span>
                © 2020{' '}
                  <a href={`${global.ApiDomain}/`} target="_blank" rel="noopener noreferrer">
                  Sistema MP
                  </a>
                  <br />
                All rights reserved
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
