import actions from './actions';

const initialState = {
  entities: [],
  loading: false,
  selected: null,
  index: {},
};

export default function bitacorasReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      let { index } = state;

      if (action.payload.entities) {
        index = action.payload.entities.reduce((acc, bitacora) => {
          return {
            ...acc,
            [bitacora.id]: bitacora,
          };
        }, {});
      }

      return { ...state, index, ...action.payload };
    }
    case actions.SET_SELECTED_BITACORA:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
}
