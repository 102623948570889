/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  obtenerBitacoras, editarBitacora, crearBitacora, eliminarBitacora,
} from 'services/bitacoras-drep';
import actions from './actions';


export function* LOAD_BITACORAS({ payload: { pacienteId } }) {
  yield put({
    type: 'bitacoras/SET_STATE',
    payload: {
      loading: true,
      entities: [],
    },
  });

  try {
    const response = yield call(obtenerBitacoras(pacienteId));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'bitacoras/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export function* EDITAR_BITACORA(bitacora) {
  try {
    const response = yield call(editarBitacora(bitacora.payload));

    if (response) {
      const { data: entities } = response;

      message.success('Se guardó correctamente');


      yield put({
        type: 'bitacoras/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* CREAR_BITACORA(bitacora) {
  try {
    const response = yield call(crearBitacora(bitacora.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'bitacoras/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* ELIMINAR_BITACORA(bitacora) {
  try {
    const response = yield call(eliminarBitacora(bitacora.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'bitacoras/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_BITACORAS, LOAD_BITACORAS),
    takeEvery(actions.EDITAR_BITACORA, EDITAR_BITACORA),
    takeEvery(actions.CREAR_BITACORA, CREAR_BITACORA),
    takeEvery(actions.ELIMINAR_BITACORA, ELIMINAR_BITACORA),
    // LOAD_BITACORAS(), // run once on app load to check user auth
  ]);
}
