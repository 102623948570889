const actions = {
  SET_STATE: 'sesiones/SET_STATE',
  LOAD_SESIONS: 'sesiones/LOAD_SESIONS',
  EDITAR_SESION: 'sesiones/EDITAR_SESION',
  CREAR_SESION: 'sesiones/CREAR_SESION',
  ELIMINAR_SESION: 'sesiones/ELIMINAR_SESION',
  SET_SELECTED_SESION: 'sesiones/SET_SELECTED_SESION',
};

export default actions;
