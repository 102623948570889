import {
  all, put, call, takeEvery,
} from 'redux-saga/effects';
import {
  getLeftMenuDataAdmi,
  getLeftMenuDataClinic,
  getLeftMenuDataEquipo,
  getLeftMenuDataNutri,
  getTopMenuData,
} from 'services/menu';

export function* GET_DATA() {
  const currentIssuerString = localStorage.getItem('issuer');
  if (!currentIssuerString) {
    return [];
  }
  const currentIssuer = JSON.parse(currentIssuerString);
  console.log(currentIssuer, 'currentIssuer');
  let menuLeftData;
  if (currentIssuer.plan === 'Nutritionist' || currentIssuer.plan === 'Nutriologo') {
    menuLeftData = yield call(getLeftMenuDataNutri);
    menuLeftData = menuLeftData.map((menu) => {
      if (menu.key === 'my-nutritionist') {
        return {
          ...menu,
          url: `${menu.url}/${currentIssuer.id}`,
        };
      }
      return menu;
    });
  } else if (currentIssuer.plan === 'Clinic' || currentIssuer.plan === 'Clinica') {
    menuLeftData = yield call(getLeftMenuDataClinic);
    menuLeftData = menuLeftData.map((menu) => {
      if (menu.key === 'my-clinic') {
        return {
          ...menu,
          url: `${menu.url}/${currentIssuer.id}`,
        };
      }
      return menu;
    });
  } else if (currentIssuer.plan === 'Sport club' || currentIssuer.plan === 'Equipo') {
    menuLeftData = yield call(getLeftMenuDataEquipo);
    menuLeftData = menuLeftData.map((menu) => {
      if (menu.key === 'my-team') {
        return {
          ...menu,
          url: `${menu.url}/${currentIssuer.id}`,
        };
      }
      return menu;
    });
  } else {
    menuLeftData = yield call(getLeftMenuDataAdmi);
  }
  const menuTopData = yield call(getTopMenuData);
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
      menuTopData,
    },
  });
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
    takeEvery('RELOAD_MENU', GET_DATA),
  ]);
}
