/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  obtenerClientes, editarCliente, crearCliente, eliminarCliente,
} from 'services/clientes';
import actions from './actions';


export function* LOAD_CLIENTES() {
  yield put({
    type: 'clientes/SET_STATE',
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(obtenerClientes);

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'clientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    // message.warning('Hubo un error al obtener los clientes');
  }
}


export function* EDITAR_CLIENTE(cliente) {
  try {
    const response = yield call(editarCliente(cliente.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'clientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al editar el elemento');
  }
}

export function* CREAR_CLIENTE(cliente) {
  try {
    const response = yield call(crearCliente(cliente.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'clientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al crear el elemento');
  }
}

export function* ELIMINAR_CLIENTE(cliente) {
  try {
    const response = yield call(eliminarCliente(cliente.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'clientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al eliminar el elemento');
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CLIENTES, LOAD_CLIENTES),
    takeEvery(actions.EDITAR_CLIENTE, EDITAR_CLIENTE),
    takeEvery(actions.CREAR_CLIENTE, CREAR_CLIENTE),
    takeEvery(actions.ELIMINAR_CLIENTE, ELIMINAR_CLIENTE),
    // LOAD_CLIENTES(), // run once on app load to check user auth
  ]);
}
