import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Radio } from 'antd';
import Card from './Card';
import './styles.scss';

function BillingPlans() {
  const [plans, setPlans] = useState([]);
  const [planType, setPlanType] = useState('MONTH');
  useEffect(() => {
    Axios.get(`${global.ApiDomain}/plans/`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    }).then((res) => {
      setPlans(res.data);
    });
  }, []);
  return (
    <div className="plan" style={{ height: 'calc(100vh - 240px)' }}>
      <div className="plan__radio">
        <Radio.Group
          value={planType}
          buttonStyle="solid"
          onChange={(e) => {
            return setPlanType(e.target.value);
          }}
        >
          <Radio.Button value="YEAR">ANUAL</Radio.Button>
          <Radio.Button value="MONTH">MENSUAL</Radio.Button>
        </Radio.Group>
      </div>
      <div className="plan__cards">
        {plans
          .sort((a, b) => {
            if (a.numeroDePacientes < b.numeroDePacientes) {
              return -1;
            }
            if (a.numeroDePacientes > b.numeroDePacientes) {
              return 1;
            }
            return 0;
          })
          .map((plan) => {
            console.log('plan ', plan);
            return <Card key={plan.id} {...plan} planType={planType} />;
          })}
      </div>
    </div>
  );
}

export default BillingPlans;
