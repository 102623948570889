import React from 'react';
import { Layout } from 'antd';
import { withRouter } from 'react-router-dom';
import styles from './style.module.scss';

@withRouter
class LoginLayout extends React.PureComponent {
  state = {
    backgroundNumber: 1,
    backgroundEnabled: false,
  }

  changeBackground = () => {
    const { backgroundNumber } = this.state;
    this.setState({
      backgroundEnabled: true,
      backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
    });
  }

  toggleBackground = () => {
    const { backgroundEnabled } = this.state;
    this.setState({
      backgroundEnabled: !backgroundEnabled,
    });
  }

  render() {
    const { children } = this.props;
    const { backgroundEnabled } = this.state;

    return (
      <Layout style={{ backgroundImage: 'linear-gradient(#000000, #132e45, #878992)' }}>
        <Layout.Content>
          <div
            className={backgroundEnabled ? `${styles.layout} ${styles.light}` : `${styles.layout}`}
            // style={{
            //   backgroundImage: backgroundEnabled
            //     ? `url('resources/images/photos/${backgroundNumber}.jpeg')`
            //     : 'none',
            // }}
            style={{ backgroundImage: 'linear-gradient(#000000 65%, #132e45, #878992)' }}
          >
            <div className={styles.content}>{children}</div>
            <div className={`${styles.footer} text-center`}>
              {/* <ul className="list-unstyled list-inline mb-3">
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Terms of Use</a>
                </li>
                <li className="active list-inline-item">
                  <a href="javascript: void(0);">Compliance</a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Confidential Information</a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Support</a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Contacts</a>
                </li>
              </ul> */}
              <ul className="list-unstyled list-inline mb-3">
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Copyright &copy; 2020 ·</a>
                </li>
                <li className="active list-inline-item">
                  <a href="javascript: void(0);">Sistema Más Poder ·</a>
                </li>
                <li className="list-inline-item">
                  <a href="javascript: void(0);">Derechos reservados</a>
                </li>
                <li className="list-inline-item">
                  <a href="/user/privacy">Aviso de privacidad</a>
                </li>
                <li className="list-inline-item">
                  <a href="/user/terms">Términos y condiciones</a>
                </li>
              </ul>
            </div>
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

export default LoginLayout;
