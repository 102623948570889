import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerPerfilesDeReferencia = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const perfilDeReferencias = await axios.get(`${global.ApiDomain}/perfiles-de-referencia/`, { headers });
  return perfilDeReferencias;
};

export const editarPerfilDeReferencia = (perfilDeReferencia) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const perfilDeReferencias = await axios.put(`${global.ApiDomain}/perfiles-de-referencia/`, perfilDeReferencia, { headers });
    return perfilDeReferencias;
  };
};

export const crearPerfilDeReferencia = (perfilDeReferencia) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const perfilDeReferencias = await axios.post(`${global.ApiDomain}/perfiles-de-referencia/`, perfilDeReferencia, { headers });
    return perfilDeReferencias;
  };
};

export const eliminarPerfilDeReferencia = (perfilDeReferencia) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const perfilDeReferencias = await axios.delete(`${global.ApiDomain}/perfiles-de-referencia/${perfilDeReferencia.id}`, { headers });
    return perfilDeReferencias;
  };
};
