/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  obtenerArchivosDeReferencia, editarArchivoDeReferencia,
  crearArchivoDeReferencia, eliminarArchivoDeReferencia,
} from 'services/reference-files';
import actions from './actions';

export function* LOAD_ARCHIVO_DE_REFERENCIAS(action) {
  console.log('🚀 ~ file: sagas.js ~ line 13 ~ function*LOAD_ARCHIVO_DE_REFERENCIAS ~ action', action);
  yield put({
    type: actions.SET_ARCHIVO_DE_REFERENCIAS,
    payload: {
      loading: true,
    },
  });

  const { patientId } = action.payload;
  console.log('🚀 ~ file: sagas.js ~ line 21 ~ function*LOAD_ARCHIVO_DE_REFERENCIAS ~ patientId', patientId);

  try {
    const response = yield call(obtenerArchivosDeReferencia(patientId));

    if (response) {
      const { data: entities } = response;
      console.log(response);
      yield put({
        type: actions.SET_ARCHIVO_DE_REFERENCIAS,
        payload: {
          loading: false,
          entities,
        },
      });

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    // message.warning('Hubo un error al obtener los archivos de referencia');
  }
}


export function* EDITAR_ARCHIVO_DE_REFERENCIA(archivoDeReferencia) {
  try {
    const response = yield call(editarArchivoDeReferencia(archivoDeReferencia.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: actions.SET_ARCHIVO_DE_REFERENCIAS,
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al editar el elemento');
  }
}

export function* CREAR_ARCHIVO_DE_REFERENCIA(archivoDeReferencia) {
  try {
    const response = yield call(
      crearArchivoDeReferencia(archivoDeReferencia.payload, archivoDeReferencia.payload.patientId),
    );

    if (response) {
      const { data: entities } = response;
      yield put({
        type: actions.SET_ARCHIVO_DE_REFERENCIAS,
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al crear el elemento');
  }
}

export function* ELIMINAR_ARCHIVO_DE_REFERENCIA(archivoDeReferencia) {
  try {
    const response = yield call(eliminarArchivoDeReferencia(archivoDeReferencia.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: actions.SET_ARCHIVO_DE_REFERENCIAS,
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al eliminar el elemento');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ARCHIVO_DE_REFERENCIAS, LOAD_ARCHIVO_DE_REFERENCIAS),
    takeEvery(actions.EDITAR_ARCHIVO_DE_REFERENCIA, EDITAR_ARCHIVO_DE_REFERENCIA),
    takeEvery(actions.CREAR_ARCHIVO_DE_REFERENCIA, CREAR_ARCHIVO_DE_REFERENCIA),
    takeEvery(actions.ELIMINAR_ARCHIVO_DE_REFERENCIA, ELIMINAR_ARCHIVO_DE_REFERENCIA),
    // LOAD_ARCHIVO_DE_REFERENCIAS(), // run once on app load to check user auth
  ]);
}
