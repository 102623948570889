import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import Loadable from 'react-loadable';
import { isChrome } from 'react-device-detect';

import Loader from 'components/LayoutComponents/Loader';
import IndexLayout from 'layouts';
import NotFoundPage from 'pages/404';
import { FormattedMessage } from 'react-intl';

const loadable = (loader) => {
  return Loadable({
    loader,
    delay: false,
    loading: () => {
      return <Loader />;
    },
  });
};

const routes = [
  // System Pages
  {
    path: '/user/login',
    component: loadable(() => {
      return import('pages/user/login');
    }),
    exact: true,
  },
  {
    path: '/user/register',
    component: loadable(() => {
      return import('pages/register');
    }),
    exact: true,
  },
  {
    path: '/user/forgot-password',
    component: loadable(() => {
      return import('pages/forgotPassword');
    }),
    exact: true,
  },
  {
    path: '/billing-plans',
    component: loadable(() => {
      return import('pages/billingPlans');
    }),
    exact: true,
  },
  {
    path: '/user/home',
    component: loadable(() => {
      return import('pages/user/home');
    }),
    exact: true,
  },
  {
    path: '/user/forgot',
    component: loadable(() => {
      return import('pages/user/forgot');
    }),
    exact: true,
  },
  {
    path: '/user/recover/:token',
    component: loadable(() => {
      return import('pages/recoverPassword');
    }),
    exact: true,
  },
  {
    path: '/edit/changepsswd/:token',
    component: loadable(() => {
      return import('pages/changePassword');
    }),
    exact: true,
  },
  {
    path: '/user/privacy',
    component: loadable(() => {
      return import('pages/user/privacy');
    }),
    exact: true,
  },
  {
    path: '/user/terms',
    component: loadable(() => {
      return import('pages/user/terms');
    }),
    exact: true,
  },
  {
    path: '/home',
    component: loadable(() => {
      return import('pages/pacientes');
    }),
    exact: true,
  },
  {
    path: '/',
    component: loadable(() => {
      return import('pages/inicio');
    }),
    exact: true,
  },

  // Default Pages
  {
    path: '/pages/login-alpha',
    component: loadable(() => {
      return import('pages/pages/login-alpha');
    }),
    exact: true,
  },
  {
    path: '/pages/login-beta',
    component: loadable(() => {
      return import('pages/pages/login-beta');
    }),
    exact: true,
  },
  {
    path: '/pages/register',
    component: loadable(() => {
      return import('pages/pages/register');
    }),
    exact: true,
  },
  {
    path: '/pages/lockscreen',
    component: loadable(() => {
      return import('pages/pages/lockscreen');
    }),
    exact: true,
  },
  {
    path: '/pages/pricing-table',
    component: loadable(() => {
      return import('pages/pages/pricing-table');
    }),
    exact: true,
  },
  {
    path: '/pages/invoice',
    component: loadable(() => {
      return import('pages/pages/invoice');
    }),
    exact: true,
  },

  // Apps

  {
    path: '/mi-dieta/:id',
    component: loadable(() => {
      return import('pages/paciente');
    }),
    exact: true,
  },
  {
    path: '/paciente/:id',
    component: loadable(() => {
      return import('pages/paciente');
    }),
    exact: true,
  },
  {
    path: '/paciente/:id/:section',
    component: loadable(() => {
      return import('pages/paciente');
    }),
    exact: true,
  },

  {
    path: '/paciente/:id/sesion/:idSesion',
    component: loadable(() => {
      return import('pages/paciente-sesion');
    }),
    exact: true,
  },
  {
    path: '/paciente/:id/sesion/:idSesion/presentacion',
    component: loadable(() => {
      return import('pages/paciente-sesion-presentacion');
    }),
    exact: true,
  },
  {
    path: '/paciente/:id/sesion/:idSesion/:tab',
    component: loadable(() => {
      return import('pages/paciente-sesion');
    }),
    exact: true,
  },
  {
    path: '/paciente/:id/sesion/:idSesion1/:idSesion2/comparacion',
    component: loadable(() => {
      return import('pages/paciente-sesion-presentacion-comparacion');
    }),
    exact: true,
  },
  {
    path: '/pacientes',
    component: loadable(() => {
      return import('pages/pacientes');
    }),
    exact: true,
  },
  {
    path: '/torneos',
    component: loadable(() => {
      return import('pages/torneos');
    }),
    exact: true,
  },
  {
    path: '/reportes',
    component: loadable(() => {
      return import('pages/reportes');
    }),
    exact: true,
  },
  {
    path: '/sesiones-de-medicion',
    component: loadable(() => {
      return import('pages/sesiones-de-medicion');
    }),
    exact: true,
  },
  {
    path: '/clientes',
    component: loadable(() => {
      return import('pages/clientes');
    }),
    exact: true,
  },
  {
    path: '/my-team/:id',
    component: loadable(() => {
      return import('pages/mi-equipo');
    }),
    exact: true,
  },
  {
    path: '/my-clinic/:id',
    component: loadable(() => {
      return import('pages/mi-equipo');
    }),
    exact: true,
  },
  {
    path: '/my-nutritionist/:id',
    component: loadable(() => {
      return import('pages/mi-equipo');
    }),
    exact: true,
  },
  {
    path: '/equipos',
    component: loadable(() => {
      return import('pages/mis-equipos');
    }),
    exact: true,
  },
  {
    path: '/my-groups/:id',
    component: loadable(() => {
      return import('pages/mi-grupo');
    }),
    exact: true,
  },
  {
    path: '/perfiles-de-referencia',
    component: loadable(() => {
      return import('pages/reference-profiles');
    }),
    exact: true,
  },
  // {
  //   path: '/archivos-de-referencia',
  //   component: loadable(() => { return import('pages/reference-files'); }),
  //   exact: true,
  // },
  {
    path: '/plans',
    component: loadable(() => {
      return import('pages/plans');
    }),
    exact: true,
  },
  {
    path: '/success',
    component: loadable(() => {
      return import('pages/paypalSuccess');
    }),
    exact: true,
  },
];

const NoChrome = () => {
  return (
    <div className="app-wrapper">
      <div className="app-main">
        <div className="app-content">
          <div className="app-content__inner">
            <div className="page-error">
              <div className="page-error__inner">
                <div className="page-error__content">
                  <div className="page-error__title">
                    <h1>
                      <span className="icon icon-browser-not-supported" />
                      <span>
                        <FormattedMessage id="This browser is not compatible" />
                      </span>
                    </h1>
                  </div>
                  <div className="page-error__desc">
                    <p>
                      <FormattedMessage id="Your browser is not compatible with our website. Please download Chrome." />
                    </p>
                  </div>
                  <div className="page-error__actions">
                    <a
                      href="https://www.google.com/chrome/browser/desktop/index.html"
                      className="btn btn-primary"
                    >
                      <FormattedMessage id="Download Chrome" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Router = (props) => {
  if (!isChrome) {
    return <NoChrome />;
  }

  const { history } = props;
  return (
    <ConnectedRouter history={history}>
      <IndexLayout>
        <Switch>
          {routes.map((route) => {
            return (
              <Route
                path={route.path}
                component={route.component}
                key={route.path}
                exact={route.exact}
              />
            );
          })}
          <Route component={NotFoundPage} />
        </Switch>
      </IndexLayout>
    </ConnectedRouter>
  );
};

export default Router;
