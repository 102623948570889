/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  obtenerEquipos, editarEquipo, crearEquipo, eliminarEquipo,
} from 'services/equipos';
import actions from './actions';


export function* LOAD_EQUIPOS() {
  yield put({
    type: 'equipos/SET_STATE',
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(obtenerEquipos);

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'equipos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    // message.warning('Hubo un error al obtener los equipos');
  }
}


export function* EDITAR_EQUIPO(equipo) {
  try {
    const response = yield call(editarEquipo(equipo.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'equipos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al editar el elemento');
  }
}

export function* CREAR_EQUIPO(equipo) {
  try {
    const response = yield call(crearEquipo(equipo.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'equipos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al crear el elemento');
  }
}

export function* ELIMINAR_EQUIPO(equipo) {
  try {
    const response = yield call(eliminarEquipo(equipo.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'equipos/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al eliminar el elemento');
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_EQUIPOS, LOAD_EQUIPOS),
    takeEvery(actions.EDITAR_EQUIPO, EDITAR_EQUIPO),
    takeEvery(actions.CREAR_EQUIPO, CREAR_EQUIPO),
    takeEvery(actions.ELIMINAR_EQUIPO, ELIMINAR_EQUIPO),
    LOAD_EQUIPOS(), // run once on app load to check user auth
  ]);
}
