import React from 'react';
import numeral from 'numeral';

function PriceCardYearly({ price }) {
  const yearLength = 12;
  const pricePerMonth = price / yearLength;
  return (
    <div className="plan__price">
      <h2>{numeral(price).format('$ 0,0[.]00')} MXN</h2>
      <span>{numeral(pricePerMonth).format('$ 0,0[.]00')} MXN por mes</span>
    </div>
  );
}

PriceCardYearly.defaultProps = {
  price: 100,
};

export default PriceCardYearly;
