import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import user from './user/reducers';
import menu from './menu/reducers';
import perfilesDeReferencia from './reference-profiles/reducers';
import archivosDeReferencia from './reference-files/reducers';
import clientes from './clientes/reducers';
import grupos from './grupos/reducers';
import pacientes from './pacientes/reducers';
import equipos from './equipos/reducers';
import gruposAlimenticios from './gruposAlimenticios/reducers';
import sesiones from './sesions/reducers';
import bitacoras from './bitacoras/reducers';
import settings from './settings/reducers';
import torneos from './torneos/reducers';
import sesionesDeMedicion from './sesiones-de-medicion/reducers';

export default (history) => {
  return combineReducers({
    router: connectRouter(history),
    archivosDeReferencia,
    bitacoras,
    clientes,
    equipos,
    grupos,
    gruposAlimenticios,
    menu,
    pacientes,
    perfilesDeReferencia,
    sesiones,
    sesionesDeMedicion,
    settings,
    torneos,
    user,
  });
};
