import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerPacientes = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const pacientes = await axios.get(`${global.ApiDomain}/pacientes/`, { headers });
  return pacientes;
};

export const editarPaciente = (paciente) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const pacientes = await axios.put(`${global.ApiDomain}/pacientes/`, paciente, { headers });
    return pacientes;
  };
};

export const crearPaciente = (paciente) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const pacientes = await axios.post(`${global.ApiDomain}/pacientes/`, paciente, { headers });
    return pacientes;
  };
};

export const eliminarPaciente = (paciente) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const pacientes = await axios.delete(`${global.ApiDomain}/pacientes/${paciente.id}`, { headers });
    return pacientes;
  };
};
