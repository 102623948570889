import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerTorneos = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const torneos = await axios.get(`${global.ApiDomain}/torneos/`, { headers });
  return torneos;
};

export const editarTorneo = (torneo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const torneos = await axios.put(`${global.ApiDomain}/torneos/`, torneo, { headers });
    return torneos;
  };
};

export const crearTorneo = (torneo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const torneos = await axios.post(`${global.ApiDomain}/torneos/`, torneo, { headers });
    return torneos;
  };
};

export const eliminarTorneo = (torneo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const torneos = await axios.delete(`${global.ApiDomain}/torneos/${torneo.id}`, { headers });
    return torneos;
  };
};
