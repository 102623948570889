/* eslint-disable no-unused-vars */
import axios from 'axios';

const getPacienteId = () => {
  const [all, pacienteId] = /paciente\/([\w-]+)\//.exec(window.location.href);
  return pacienteId;
};

// eslint-disable-next-line import/prefer-default-export
export const obtenerSesions = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const pacienteId = getPacienteId();
  const sesions = await axios.get(`${global.ApiDomain}/sesions/${pacienteId}/`, { headers });
  console.log('obtenerSesions -> sesions', sesions);
  return sesions;
};

export const editarSesion = (sesion) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const pacienteId = getPacienteId();
    const sesions = await axios.put(`${global.ApiDomain}/sesions/${pacienteId}/`, sesion, {
      headers,
    });
    return sesions;
  };
};

export const crearSesion = (sesion) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const pacienteId = getPacienteId();

    const sesions = await axios.post(`${global.ApiDomain}/sesions/${pacienteId}/`, sesion, {
      headers,
    });
    return [sesions, pacienteId];
  };
};

export const eliminarSesion = (sesion) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const pacienteId = getPacienteId();
    const sesions = await axios.delete(`${global.ApiDomain}/sesions/${pacienteId}/${sesion.id}`, {
      headers,
    });
    return sesions;
  };
};
