import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerEquipos = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const equipos = await axios.get(`${global.ApiDomain}/equipos/`, { headers });
  return equipos;
};

export const editarEquipo = (equipo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const equipos = await axios.put(`${global.ApiDomain}/equipos/`, equipo, { headers });
    return equipos;
  };
};

export const crearEquipo = (equipo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const equipos = await axios.post(`${global.ApiDomain}/equipos/`, equipo, { headers });
    return equipos;
  };
};

export const eliminarEquipo = (equipo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const equipos = await axios.delete(`${global.ApiDomain}/equipos/${equipo.id}`, { headers });
    return equipos;
  };
};
