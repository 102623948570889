import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerSesionDeMedicions = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const sesiondemedicions = await axios.get(`${global.ApiDomain}/sesion-de-medicion/`, { headers });
  return sesiondemedicions;
};

export const editarSesionDeMedicion = (sesiondemedicion) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const sesiondemedicions = await axios.put(`${global.ApiDomain}/sesion-de-medicion/`, sesiondemedicion, { headers });
    return sesiondemedicions;
  };
};

export const crearSesionDeMedicion = (sesiondemedicion) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const sesiondemedicions = await axios.post(`${global.ApiDomain}/sesion-de-medicion/`, sesiondemedicion, { headers });
    return sesiondemedicions;
  };
};

export const eliminarSesionDeMedicion = (sesiondemedicion) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const sesiondemedicions = await axios.delete(`${global.ApiDomain}/sesion-de-medicion/${sesiondemedicion.id}`, { headers });
    return sesiondemedicions;
  };
};
