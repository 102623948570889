import React from 'react';
import { FormattedMessage } from 'react-intl';

export async function getLeftMenuDataAdmi() {
  return [
    {
      title: <FormattedMessage id="menu.inicio" />,
      key: 'inicio',
      url: '/',
      icon: 'fas fa-home',
    },
    {
      title: <FormattedMessage id="menu.perfilesDeReferencia" />,
      key: 'perfiles-de-referencia',
      url: '/perfiles-de-referencia',
      icon: 'fas fa-street-view',
    },
    {
      divider: true,
    },
    {
      title: <FormattedMessage id="menu.clientes" />,
      key: 'clientes',
      url: '/clientes',
      icon: 'fas fa-file-invoice-dollar',
    },
  ];
}

export async function getLeftMenuDataEquipo() {
  return [
    {
      title: <FormattedMessage id="menu.inicio" />,
      key: 'inicio',
      url: '/',
      icon: 'fas fa-home',
    },
    {
      title: <FormattedMessage id="menu.myTeam" />,
      key: 'my-team',
      url: '/my-team',
      icon: 'fas fa-futbol',
    },
    {
      title: <FormattedMessage id="menu.equipos" />,
      key: 'equipos',
      url: '/equipos',
      icon: 'fas fa-tshirt',
    },
    {
      title: <FormattedMessage id="menu.jugadores" />,
      key: 'pacientes',
      url: '/pacientes',
      icon: 'fas fa-diagnoses',
    },
    {
      title: <FormattedMessage id="menu.reportes" />,
      key: 'reportes',
      url: '/reportes',
      icon: 'fas fa-chart-bar',
    },
    {
      divider: true,
    },
    // {
    //   title: <FormattedMessage id="menu.archivosDeReferencia" />,
    //   key: 'archivos-de-referencia',
    //   url: '/archivos-de-referencia',
    //   icon: 'fas fa-file-pdf',
    // },
    {
      title: <FormattedMessage id="menu.perfilesDeReferencia" />,
      key: 'perfiles-de-referencia',
      url: '/perfiles-de-referencia',
      icon: 'fas fa-street-view',
    },
    {
      divider: true,
    },
  ];
}

export async function getLeftMenuDataNutri() {
  return [
    {
      title: <FormattedMessage id="menu.inicio" />,
      key: 'inicio',
      url: '/',
      icon: 'fas fa-home',
    },

    {
      divider: true,
    },
    {
      title: <FormattedMessage id="menu.myProfile" />,
      key: 'my-nutritionist',
      url: '/my-nutritionist',
      icon: 'fab fa-nutritionix',
    },
    {
      title: <FormattedMessage id="menu.pacientes" />,
      key: 'pacientes',
      url: '/pacientes',
      icon: 'fas fa-diagnoses',
    },
    // {
    //   title: <FormattedMessage id="menu.archivosDeReferencia" />,
    //   key: 'archivos-de-referencia',
    //   url: '/archivos-de-referencia',
    //   icon: 'fas fa-file-pdf',
    // },
    {
      title: <FormattedMessage id="menu.perfilesDeReferencia" />,
      key: 'perfiles-de-referencia',
      url: '/perfiles-de-referencia',
      icon: 'fas fa-street-view',
    },
  ];
}

export async function getLeftMenuDataClinic() {
  return [
    {
      title: <FormattedMessage id="menu.inicio" />,
      key: 'inicio',
      url: '/',
      icon: 'fas fa-home',
    },
    {
      title: <FormattedMessage id="menu.myClinic" />,
      key: 'my-clinic',
      url: '/my-clinic',
      icon: 'fab fa-nutritionix',
    },
    {
      divider: true,
    },
    {
      title: <FormattedMessage id="menu.pacientes" />,
      key: 'pacientes',
      url: '/pacientes',
      icon: 'fas fa-diagnoses',
    },
    {
      title: <FormattedMessage id="menu.equipos" />,
      key: 'equipos',
      url: '/equipos',
      icon: 'fas fa-tshirt',
    },
    {
      title: <FormattedMessage id="menu.reportes" />,
      key: 'reportes',
      url: '/reportes',
      icon: 'fas fa-chart-bar',
    },
    // {
    //   title: <FormattedMessage id="menu.archivosDeReferencia" />,
    //   key: 'archivos-de-referencia',
    //   url: '/archivos-de-referencia',
    //   icon: 'fas fa-file-pdf',
    // },
    {
      title: <FormattedMessage id="menu.perfilesDeReferencia" />,
      key: 'perfiles-de-referencia',
      url: '/perfiles-de-referencia',
      icon: 'fas fa-street-view',
    },


  ];
}

export async function getTopMenuData() {
  return [];
}
