import { all } from 'redux-saga/effects';
import user from './user/sagas';
import menu from './menu/sagas';
import referenceProfiles from './reference-profiles/sagas';
import referenceFiles from './reference-files/sagas';
import clientes from './clientes/sagas';
import grupos from './grupos/sagas';
import pacientes from './pacientes/sagas';
import sesions from './sesions/sagas';
import bitacoras from './bitacoras/sagas';
import equipos from './equipos/sagas';
import settings from './settings/sagas';

import sesionesDeMedicion from './sesiones-de-medicion/sagas';
import torneos from './torneos/sagas';

export default function* rootSaga() {
  yield all([
    user(),
    referenceProfiles(),
    referenceFiles(),
    sesions(),
    bitacoras(),
    equipos(),
    clientes(),
    grupos(),
    pacientes(),
    menu(),
    sesionesDeMedicion(),
    torneos(),
    settings(),
  ]);
}
