import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function gruposAlimenticiosReducer() {
  return {
    index: {
      cereal: {
        nombre: <FormattedMessage id="foodGroup.cereal" />,
        color: '#9f4d18',
        key: 'dietaCereal',
        name: 'alimento.cereal',
        // kCalTotales: 80,
        // porcionesProteina: 2,
        // porcionesCarbohidratos: 15,
        // porcionesLipidos: 0,
      },
      grasa: {
        nombre: <FormattedMessage id="foodGroup.grasa" />,
        color: '#fbb71c',
        key: 'dietaGrasa',
        name: 'alimento.grasa',
        // kCalTotales: 45,
        // porcionesProteina: 0,
        // porcionesCarbohidratos: 0,
        // porcionesLipidos: 5,
      },
      proteina: {
        nombre: <FormattedMessage id="foodGroup.proteina" />,
        color: '#e8383b',
        key: 'dietaProteina',
        name: 'alimento.proteina',
        // kCalTotales: 75,
        // porcionesProteina: 7,
        // porcionesCarbohidratos: 0,
        // porcionesLipidos: 5,
      },
      proteina2: {
        nombre: <FormattedMessage id="foodGroup.proteina" />,
        color: '#e8383b',
        key: 'dietaProteina2',
        name: 'alimento.proteinaOtro',
        // kCalTotales: 75,
        // porcionesProteina: 7,
        // porcionesCarbohidratos: 0,
        // porcionesLipidos: 5,
      },
      lecheDescremada: {
        nombre: <FormattedMessage id="foodGroup.lecheDescremada" />,
        color: '#12aee0',
        key: 'dietaLecheDescremada',
        name: 'alimento.lácteos',
        // kCalTotales: 100,
        // porcionesProteina: 9,
        // porcionesCarbohidratos: 12,
        // porcionesLipidos: 2,
      },
      quesos: {
        nombre: <FormattedMessage id="foodGroup.quesos" />,
        color: '#12aee0',
        key: 'dietaQueso',
        name: 'alimento.lácteosOtro',
        // kCalTotales: 75,
        // porcionesProteina: 8,
        // porcionesCarbohidratos: 3,
        // porcionesLipidos: 4,
      },
      verdura: {
        nombre: <FormattedMessage id="foodGroup.verdura" />,
        color: '#99c222',
        key: 'dietaVerdura',
        name: 'alimento.verdura',
        // kCalTotales: 25,
        // porcionesProteina: 2,
        // porcionesCarbohidratos: 5,
        // porcionesLipidos: 0,
      },

      fruta: {
        nombre: <FormattedMessage id="foodGroup.fruta" />,
        color: '#f18d45',
        key: 'dietaFruta',
        name: 'alimento.fruta',
        // kCalTotales: 60,
        // porcionesProteina: 0,
        // porcionesCarbohidratos: 15,
        // porcionesLipidos: 0,
      },

      proteinaPolvo: {
        nombre: <FormattedMessage id="foodGroup.proteinaPolvoConCarb" />,
        color: '#9065f2',
        key: 'dietaProteinaPolvo',
        name: 'alimento.protEnPolvoConCarb',
        // kCalTotales: 189.5,
        // porcionesProteina: 20,
        // porcionesCarbohidratos: 24,
        // porcionesLipidos: 1.5,
      },
      dietaProteinaPolvoZeroCarbs: {
        nombre: <FormattedMessage id="foodGroup.proteinaPolvoSinCarb" />,
        color: '#9065f2',
        key: 'dietaProteinaPolvoZeroCarbs',
        name: 'alimento.protEnPolvoSinCarb',
        // kCalTotales: 106.5,
        // porcionesProteina: 25,
        // porcionesCarbohidratos: 1,
        // porcionesLipidos: 0.5,
      },
      bebidaDeportiva: {
        nombre: <FormattedMessage id="foodGroup.bebidaDeportiva" />,
        color: '#9065f2',
        key: 'dietaBebidaDeportiva',
        name: 'alimento.bebidaDeportiva',
        // kCalTotales: 150,
        // porcionesProteina: 0,
        // porcionesCarbohidratos: 36,
        // porcionesLipidos: 0,
      },
      gel: {
        nombre: <FormattedMessage id="foodGroup.gel" />,
        color: '#9065f2',
        key: 'dietaGel',
        name: 'alimento.gel',
        // kCalTotales: 100,
        // porcionesProteina: 0,
        // porcionesCarbohidratos: 25,
        // porcionesLipidos: 0,
      },
    },
  };
}
