import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerClientes = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const clientes = await axios.get(`${global.ApiDomain}/clientes/`, { headers });
  return clientes;
};

export const editarCliente = (cliente) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const clientes = await axios.put(`${global.ApiDomain}/clientes/`, cliente, { headers });
    return clientes;
  };
};

export const crearCliente = (cliente) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const clientes = await axios.post(`${global.ApiDomain}/clientes/`, cliente, { headers });
    return clientes;
  };
};

export const eliminarCliente = (cliente) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const clientes = await axios.delete(`${global.ApiDomain}/clientes/${cliente.id}`, { headers });
    return clientes;
  };
};
