/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  obtenerPerfilesDeReferencia, editarPerfilDeReferencia,
  crearPerfilDeReferencia, eliminarPerfilDeReferencia,
} from 'services/reference-profiles';
import actions from './actions';

export function* LOAD_PERFIL_DE_REFERENCIAS() {
  yield put({
    type: actions.SET_PERFIL_DE_REFERENCIAS,
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(obtenerPerfilesDeReferencia);

    if (response) {
      const { data: entities } = response;
      console.log(response);


      yield put({
        type: actions.SET_PERFIL_DE_REFERENCIAS,
        payload: {
          loading: false,
          entities,
        },
      });

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    // message.warning('Hubo un error al obtener los perfiles de referencia');
  }
}


export function* EDITAR_PERFIL_DE_REFERENCIA(perfilDeReferencia) {
  try {
    const response = yield call(editarPerfilDeReferencia(perfilDeReferencia.payload));

    if (response) {
      // const { data: entities } = response;
      // yield put({
      //   type: actions.SET_PERFIL_DE_REFERENCIAS,
      //   payload: {
      //     loading: false,
      //     entities,
      //   },
      // });

      yield put({
        type: actions.LOAD_PERFIL_DE_REFERENCIAS,
      });
    }
  } catch (error) {
    message.warning('Hubo un error al editar el elemento');
  }
}

export function* CREAR_PERFIL_DE_REFERENCIA(perfilDeReferencia) {
  try {
    const response = yield call(crearPerfilDeReferencia(perfilDeReferencia.payload));

    if (response) {
      // const { data: entities } = response;
      // yield put({
      //   type: actions.SET_PERFIL_DE_REFERENCIAS,
      //   payload: {
      //     loading: false,
      //     entities,
      //   },
      // });
      yield put({
        type: actions.LOAD_PERFIL_DE_REFERENCIAS,
      });
    }
  } catch (error) {
    message.warning('Hubo un error al crear el elemento');
  }
}

export function* ELIMINAR_PERFIL_DE_REFERENCIA(perfilDeReferencia) {
  try {
    const response = yield call(eliminarPerfilDeReferencia(perfilDeReferencia.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: actions.SET_PERFIL_DE_REFERENCIAS,
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning('Hubo un error al eliminar el elemento');
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PERFIL_DE_REFERENCIAS, LOAD_PERFIL_DE_REFERENCIAS),
    takeEvery(actions.EDITAR_PERFIL_DE_REFERENCIA, EDITAR_PERFIL_DE_REFERENCIA),
    takeEvery(actions.CREAR_PERFIL_DE_REFERENCIA, CREAR_PERFIL_DE_REFERENCIA),
    takeEvery(actions.ELIMINAR_PERFIL_DE_REFERENCIA, ELIMINAR_PERFIL_DE_REFERENCIA),
    LOAD_PERFIL_DE_REFERENCIAS(), // run once on app load to check user auth
  ]);
}
