import { notification } from 'antd';
import Axios from 'axios';

export async function login(email, password) {
  const data = {
    user: email,
    password,
  };

  const dataString = JSON.stringify(data);

  const config = {
    method: 'post',
    url: `${global.ApiDomain}/login/simulated/`,
    data: dataString,
  };

  try {
    const response = await Axios.post(config.url, config.data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!response.data.issuer) {
      notification.error({
        message: 'No puedes iniciar sesión aquí, descarga la app móvil en su lugar.',
      });
      return false;
    }
    // if (response.data.rol.toLowerCase() === 'paciente') {
    //   notification.error({
    //     message: 'No puedes iniciar sesión aquí, descarga la app móvil en su lugar.',
    //   });
    //   return false;
    // }

    const { token } = response.data;

    localStorage.setItem('token', token);
    window.location.reload();
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });
    return true;
  } catch (err) {
    notification.error({
      message: err.response.data.message.spanish,
    });
    return false;
  }
}

export async function currentAccount() {
  const accessToken = localStorage.getItem('token');
  try {
    const user = await getUser(accessToken);
    return user;
  } catch (error) {
    return error;
  }
}

export async function logout() {
  localStorage.removeItem('token');
  localStorage.removeItem('rol');
  localStorage.removeItem('vigente');
  localStorage.removeItem('issuer');

  return true;
}

export async function getUser(token) {
  const config = {
    method: 'post',
    url: `${global.ApiDomain}/me/`,
  };

  try {
    const response = await Axios.get(config.url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data === '' && !window.location.pathname.includes('user')) {
      window.location.href = '/user/login';
    }
    if (!response.data.issuer && response.data !== '') {
      notification.error({
        message: 'No puedes iniciar sesión aquí, descarga la app móvil en su lugar.',
      });
      return false;
    }

    // console.log('response', response);
    return response.data;
  } catch (err) {
    notification.error({
      message: err.response.data.message.spanish,
    });
    return false;
  }
}
