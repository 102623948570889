import actions from './actions';

const initialState = {
  entities: [],
  loading: false,
  index: {},
};

export default function equiposReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE: {
      let { index } = state;

      if (action.payload.entities) {
        index = action.payload.entities.reduce((acc, sesion) => {
          return {
            ...acc,
            [sesion.id]: sesion,
          };
        }, {});
      }

      return { ...state, index, ...action.payload };
    }
    default:
      return state;
  }
}
