import antdData from 'antd/lib/locale-provider/en_US';
import localeData from 'react-intl/locale-data/en';

const messages = {
  pacientes: 'Patients',

  'configuracion.de.usuarios': 'User configuration',
  'categorias.equipos': 'Categories / Teams',
  'tableHeader.playerName': 'Player Name',
  'tableHeader.playerNumber': 'Player Number',
  'tableHeader.app': 'App',
  'tableHeader.playerPosition': 'Player Position',
  'tableHeader.days': 'Days since the last session',
  'mi.club': 'My club',
  'menu.reportes': 'Reports',
  'menuPrincipal.Documentos': 'Files ',

  'football.description':
    'Working with high-performance soccer players is a complex task, which is why we offer you this system that will improve the way you used to do it. The objective of this system is to provide the nutrition team with a tool where they can: create complete player files, calculate meal plans, monitor changes in body composition both by 5 components as well as by 2 components depending on the desired objectives, globally compare the nutritional and physical status of the team. Having the advantage of providing team managers with real-time information on how the players are, to create improvement strategies if necessary.',

  'Bienvenido al Sistema MP': 'Welcome to the MP System',
  Pacientes: 'Patients',
  Documentos: 'Documents',
  Perfiles: 'Profiles',
  Nutriólogos: 'Nutritionists',

  'Football teams': 'Soccer team',

  'Sistema MP - Mas poder': 'MP System - More power',

  'welcome.nutriologo':
    'Being a Certified Nutritionist in Sports Nutrition, we provide a system that aims for you: to optimize the records of your patients, generate a database of the progress of your patients, create charts that will help the patient to visually understand the path of his progress and, above all, give the patient a tool that will help him to record his feeding plan and monitor his progress, all through his cell phone, generating an accompaniment beyond the consultation.',

  'alert.campoVacio': 'This field cannot be empty',
  'alert.borrarElemento': 'Do you want to delete this element?',
  'alert.borrarElementoMensaje': 'You can delete data from the past related to this element',
  'alert.quitarElemento': 'Do you want to remove this element?',
  'alert.quitarElementoMensaje': 'This action does not delete this user',
  'alert.campoRequerido': 'This field is required',
  'alert.campoImportante':
    "This field is important to track and calculate the patient's age at the time of measurement.",
  'alert.nombreVacio': "Name can't be empty",
  'alert.emailVacio': "Email can't be empty",
  'alert.contrasenaVacia': "Password can't be empty",
  'alert.fechaNacimientoVacia': "Birth date can't be empty",
  'alert.edadVacia': "Age can't be empty",
  'alert.deporteVacio': "Sport/activity can't be empty",
  'alert.indiceMusculoOseo': 'Field can not be empty',
  'alert.sumatoriaDe6Pliegues': 'Field can not be empty',

  'archivoReferencia.crear': 'Create a new reference file',
  'archivoReferencia.paraQuienSeraVisible': 'Who will these files be visible to?',
  'archivoReferencia.descripcion': 'Upload a file and share it with your patient',
  'archivoReferencia.visibilidad.soloYo': 'Only me',
  'archivoReferencia.visibilidad.nutriologosPacientes': 'Nutritionists and patients',
  'archivoReferencia.visibilidad.soloNutriologos': 'Nutritionists only',
  'archivoReferencia.visibilidad.soloPacientes': 'Patients only',
  'archivoReferencia.cargarArchivo': 'Upload file',
  'archivoReferencia.subirPDF': 'Click here or drag a PDF file to this area',
  'archivoReferencia.subirPDFMensaje':
    'When the file is uploaded you can modify the privacy so that only the specialist or the patient or both can see it',
  'archivoReferencia.examinar': 'Examine',
  'archivoReferencia.cargaExitosa': 'uploaded successfully',
  'archivoReferencia.falloCarga': 'Failed to charge',

  'bodyMass.adiposa': 'Fat',
  'bodyMass.muscular': 'Muscle',
  'bodyMass.residual': 'Residual',
  'bodyMass.osea': 'Bone',
  'bodyMass.piel': 'Skin',

  'buttons.remove': 'Remove',
  'buttons.view': 'See details',
  'buttons.cancel': 'Cancel',
  'buttons.add': 'Add',
  'buttons.save': 'Save changes',
  'buttons.saveClose': 'Save and close',
  'buttons.measureGrowth': 'Measure growth',
  'buttons.noMeasureGrowth': 'Not measure',
  'buttons.fiveComponents': '5 Components',
  'buttons.twoComponents': '2 Components',
  'buttons.highPerformance': 'High performance',
  'buttons.weightControl': 'Weight control',
  'buttons.seeResults': 'See results',
  'buttons.print': 'Print',
  'buttons.delete': 'Delete',
  'buttons.create': 'Create',
  'buttons.close': 'Close',
  'buttons.addMeasurement': 'Add measurement',
  'buttons.copyValuesFrom': 'Copy values from...',
  'buttons.removeFromGroup': 'Remove from group',
  'buttons.siguiente': 'Next',
  'buttons.terminar': 'Finish',

  'calendario.citasDe': 'Appointments of',

  'table.date': 'Date',
  'table.training': 'Training type',
  'table.water': 'Water intake',
  'table.activity': 'Activity',
  'table.portions': 'Portions',

  'chartTitle.massKg': 'Mass (kg)',
  'chartTitle.weight': 'Weight (kg)',
  'chartTitle.muscleMass': 'Muscle mass (kg)',
  'chartTitle.fatMass': 'Fat mass (kg)',
  'chartTitle.foldsSum': 'Sum of skinfolds (mm)',

  'client.createClient': 'Create a new client',

  'groups.createGroup': 'Create a new category',
  'groups.addMembers': 'Add patients to the group',

  'gender.male': 'Male',
  'gender.female': 'Female',

  'general.lastVisit': 'Last appointment',

  'breadcrumb.home': 'Home',

  'header.detallesSesion': 'Session details',

  'imageAlt.graficaCuerpo': 'Body chart',
  'imageAlt.graficaNino': 'Boy chart',
  'imageAlt.graficaNina': 'Girl chart',
  'imageAlt.logoClinica': 'Clinic logo',

  'labData.dato': 'Element',
  'labData.medicion': 'Measurement',
  'labData.diagnosticoBioquimicos': 'Biochemical diagnosis',
  'labData.potasio': 'Potassium',
  'labData.cloro': 'Chlorine',
  'labData.bicarbonato': 'Sodium bicarbonate Co2',
  'labData.glucosa': 'Glucose',
  'labData.urea': 'Urea (Bun)',
  'labData.creatinina': 'Creatinine',
  'labData.gfrOther': 'Gfr Est-other',
  'labData.gfrAfricanamerican': 'Gfr Est-african American',
  'labData.calcio': 'Calcium',
  'labData.proteinaTotal': 'Total Protein',
  'labData.albumina': 'Albumin',
  'labData.bilirrubinaTotal': 'Total bilirubin',
  'labData.fosfatoAlcalino': 'Alkaline Phosphate',
  'labData.ast': 'AST',
  'labData.alt': 'ALT',
  'labData.wbc': 'Wbc',
  'labData.rbc': 'Rbc',
  'labData.hemoglobina': 'Hemoglobin',
  'labData.hematrocito': 'Hematocrit',
  'labData.mcv': 'MCV',
  'labData.mch': 'MCH',
  'labData.mchc': 'Mchc',
  'labData.rdw': 'Rdw',
  'labData.plaquetas': 'Platelets',
  'labData.colesterol': 'Cholesterol',
  'labData.cortisol': 'Cortisol',
  'labData.cortisolTestosterona': 'Cortisol/Testosterone',
  'labData.testosteronaTotal': 'Total testosterone',
  'labData.testosteronaLibre': 'Free testosterone',
  'labData.trigliceridos': 'Triglycerides',
  'labData.hdl': 'Hdl Cholesterol',
  'labData.ldl': 'Ldl Cholesterol',
  'labData.colesterolHdl': 'Cholesterol/Hdl/',
  'labData.rubellaIgg': 'Rubella Igg',
  'labData.hemoglobinaA1c': 'Hemoglobin A1c',
  'labData.crpUltraquant': 'Crp-ultraquant',
  'labData.ferritina': 'Ferritin',
  'labData.vitaminaD': 'Vitamin D, 25-hidroxi',

  'massType.muscle': 'Muscular',
  'massType.fat': 'Adipose',

  'menu.inicio': 'Home',
  'menu.entry': 'Entry',
  'menu.usuarios': 'Users',
  'menu.interesados': 'Stakeholders',

  'menu.pacientes': 'Patients',
  'menu.jugadores': 'Players',
  'menu.sesions': 'Sessions',
  'menu.sesiones': 'Sessions',
  'menu.sesionesDeMedicion': 'Measurement sessions',
  'menu.schedule': 'Appointments',
  'menu.perfilesDeReferencia': 'Reference profiles',
  'menu.archivosDeReferencia': 'Reference files',
  'menu.clientes': 'Clients',
  'menu.equipos': 'Categories',
  'menu.alimentosYPorciones': 'Food and portions',
  'menu.dashboard': 'Dashboard',
  'menu.myGroups': 'My categories',
  'menu.myClinic': 'My clinic',
  'menu.myProfile': 'My profile',
  'menu.myTeam': 'My team/club',
  'menu.groupName': 'Name',
  'menu.groupMembers': 'Members',
  'menu.image': 'User image',
  'menu.data': 'User data',

  'user.name': 'Name',
  'user.photo': 'Photo',

  'search.placeholder.name': 'Enter a name',
  'search.placeholder.find': 'Type to start searching',
  'search.button': 'Search',

  'patientList.title': 'Patient list',

  'patientTab.datosPaciente': "Patient's data",
  'patientTab.progress': "Patient's data",
  'patientTab.progressTable': "Patient's data (table)",
  'patientTab.medicionesResultados': 'Consultations',
  'patientTab.Growth': 'Growth',
  'patientTab.calculoDietetico': 'Dietary calculation',
  'patientTab.registroAlimentos': 'Patient food record',
  'patientTab.archivosPaciente': 'Patient files',
  'patientTab.porcionesPaciente': 'Food record',

  'patients.crear': 'Create a new patient',
  'patients.nombre': 'Name',
  'patients.celular': 'Cell phone number',
  'patients.fechaNacimiento': 'Date of birth',
  'patients.tipoConsulta': 'Appointment type',
  'patients.options.altoRendimiento': 'High performance',
  'patients.options.controlPeso': 'Weight control',
  'patients.controlCrecimiento': 'Growth control',
  'patients.options.medirCrecimiento': 'Measure growth',
  'patients.options.noMedir': 'Not measure',
  'patients.email': 'Email',
  'patients.deportePosicion': 'Sport/Position/Activity',
  'patients.expediente': 'Record',
  'patients.fechaUltimaSesion': 'Date of last session',

  'calculoDietetico.metabolismoBasal': 'Basal metabolism',
  'calculoDietetico.seleccionaInvestigador': 'Select the researcher',
  'calculoDietetico.kcalDiarias': 'Daily Kcal',
  'calculoDietetico.seleccionaNivelActividadPaciente':
    "Select the patient's activity level to calculate GET (WHO 1985)",
  'calculoDietetico.ignorarFactor': 'Ignore activity factor',
  'calculoDietetico.sedentario': 'Sedentary',
  'calculoDietetico.liviano': 'Light',
  'calculoDietetico.moderado': 'Moderate',
  'calculoDietetico.fuerte': 'Heavy',
  'calculoDietetico.extremo': 'Extreme',
  'calculoDietetico.gastoEnergeticoTotal': 'Total energy expenditure',
  'calculoDietetico.perdidaGrasa': 'Loss of fat',
  'calculoDietetico.calculoDieta': 'Diet calculation',
  'calculoDietetico.numero': 'Number',
  'calculoDietetico.kcal': 'Kcal',
  'calculoDietetico.proteinas': 'Protein',
  'calculoDietetico.carbohidratos': 'Carbohydrates',
  'calculoDietetico.lipidos': 'Lipids',
  'calculoDietetico.perdidaGrasaAumentoMusculo': 'Loss of fat -500Kcal, muscle gain +500Kcal',
  'calculoDietetico.total': 'Total',

  'datosPaciente.datosBasicos': 'Basic data',
  'datosPaciente.bioquimicos': 'Biochemical',
  'datosPaciente.darAlta': 'Register',

  'datosCliente.contrasena': 'Password',

  'datosBasicos.informacionPersonal': 'Personal information',
  'datosBasicos.informacionConsulta': 'Appointment information',
  'datosBasicos.frecuenciaAlimentos': 'Food frequency (number of times per week, from 0 to 7)',
  'datosBasicos.relatoAlimentacion': 'Feeding narration',
  'datosBasicos.datosSalud': 'Health data',
  'datosBasicos.datosActividadFisica': 'Physical activity data',

  'informacionPersonal.nombre': 'Name',
  'informacionPersonal.email': 'Email',
  'informacionPersonal.celular': 'Cell phone number',
  'informacionPersonal.url': 'Url',
  'informacionPersonal.recomendadoPor': 'Recommended by',
  'informacionPersonal.controlCrecimiento': 'Growth control',
  'informacionPersonal.fechaNacimiento': 'Date of birth',
  'informacionPersonal.genero': 'Gender',
  'informacionPersonal.enviarPorEmail': 'Send by email',
  'informacionPersonal.indiceCrecimiento': 'Growth index',
  'informacionPersonal.edadPHV': 'Age PHV',
  'informacionPersonal.clasMasc': 'Clas masc',

  'informacionConsulta.numeroComponentes': 'Number of components',
  'informacionConsulta.tipoConsulta': 'Appointment type',
  'informacionConsulta.perfilReferencia': 'Reference profile',

  'frecuenciaAlimentos.lacteos': 'Dairy products',
  'frecuenciaAlimentos.carnesRojas': 'Red meat',
  'frecuenciaAlimentos.pollo': 'Chicken',
  'frecuenciaAlimentos.pescadoMariscos': 'Fish or seafood',
  'frecuenciaAlimentos.fruta': 'Fruits',
  'frecuenciaAlimentos.verdura': 'Vegetables',
  'frecuenciaAlimentos.huevo': 'Egg',
  'frecuenciaAlimentos.panTortilla': 'Bread or tortilla',
  'frecuenciaAlimentos.arrozPasta': 'Rice or pasta',
  'frecuenciaAlimentos.leguminosas': 'Legumes',
  'frecuenciaAlimentos.semillasOleaginosas': 'Oilseeds',
  'frecuenciaAlimentos.alcohol': 'Alcohol',
  'frecuenciaAlimentos.refrescos': 'Refreshments',
  'frecuenciaAlimentos.cafe': 'Coffee',
  'frecuenciaAlimentos.golosinas': 'Candies',
  'frecuenciaAlimentos.botanas': 'Snacks',
  'frecuenciaAlimentos.postres': 'Desserts',
  'frecuenciaAlimentos.agua': 'Water',
  'frecuenciaAlimentos.aguaLitroDia': 'Water (Liters per day)',
  'frecuenciaAlimentos.lacteos.tooltip': 'Milk, yogurt, curd, cheese, etc...',
  'frecuenciaAlimentos.carnesRojas.tooltip': 'Beef, pork, turkey, etc...',
  'frecuenciaAlimentos.pollo.tooltip': ' ',
  'frecuenciaAlimentos.pescadoMariscos.tooltip': ' ',
  'frecuenciaAlimentos.fruta.tooltip': ' ',
  'frecuenciaAlimentos.verdura.tooltip': ' ',
  'frecuenciaAlimentos.huevo.tooltip': ' ',
  'frecuenciaAlimentos.panTortilla.tooltip': ' ',
  'frecuenciaAlimentos.arrozPasta.tooltip': ' ',
  'frecuenciaAlimentos.leguminosas.tooltip': 'Bean, lentil, soy, chickpeas, etc...',
  'frecuenciaAlimentos.semillasOleaginosas.tooltip': 'Nuts, almonds, sesame, etc...',
  'frecuenciaAlimentos.alcohol.tooltip': ' ',
  'frecuenciaAlimentos.refrescos.tooltip': ' ',
  'frecuenciaAlimentos.cafe.tooltip': ' ',
  'frecuenciaAlimentos.golosinas.tooltip': 'Candy, lollipops',
  'frecuenciaAlimentos.botanas.tooltip': ' ',
  'frecuenciaAlimentos.postres.tooltip': 'Cakes, milkshakes, cookies',
  'frecuenciaAlimentos.agua.tooltip': 'Water (frequency)',
  'frecuenciaAlimentos.aguaLitroDia.tooltip': 'Water (Liters per day)',

  'foodGroup.cereal': 'Cereal',
  'foodGroup.grasa': 'Fat',
  'foodGroup.proteina': 'Protein',
  'foodGroup.lecheDescremada': 'Dairy',
  'foodGroup.quesos': 'Cheeses',
  'foodGroup.verdura': 'Vegetables',
  'foodGroup.fruta': 'Fruits',
  'foodGroup.proteinaPolvoConCarb': 'Protein powder with carbohydrates',
  'foodGroup.proteinaPolvoSinCarb': 'Protein powder without carbohydrates',
  'foodGroup.bebidaDeportiva': 'Sports drink',
  'foodGroup.gel': 'Gel',
  'foodGroup.lacteos': 'Dairy products',
  'foodGroup.suplementos': 'Supplements',

  'format.date': 'MMMM DD, YYYY',
  'format.dateTime': 'MMMM DD, YYYY [at] HH:mm',

  'menu.calculo.equivalentes': 'Configuration of equivalent portions',

  'registroAlimentos.bitacoraAlimenticia': 'Food log',
  'registroAlimentos.fecha': 'Date',
  'registroAlimentos.actividadFisica': 'Physical activity',
  'registroAlimentos.duracionMinutos': 'Duration in minutes',
  'registroAlimentos.litrosAgua': 'Liters of water',
  'registroAlimentos.dia': 'Day',
  'registroAlimentos.consumidas': 'Consumed',
  'registroAlimentos.total': 'Total',
  'registroAlimentos.desayuno': 'Breakfast',
  'registroAlimentos.colacion': 'Collation',
  'registroAlimentos.comida': 'Lunch',
  'registroAlimentos.cena': 'Dinner',

  'relatoAlimentacion.descripcion': 'Describe a day of your diet',

  'datosSalud.problemasSalud': 'Health problems',
  'datosSalud.suplementos': 'Supplements',
  'datosSalud.hidratacion': 'Hydration',
  'datosSalud.observaciones': 'Observations',

  'masa.adiposa': 'Adipose mass',
  'masa.adiposaPorcentaje': 'Percentage of adipose mass',
  'masa.residual': 'Residual mass',
  'masa.osea': 'Bone mass',
  'masa.oseaPorcentaje': 'Percentage of bone mass',
  'masa.muscular': 'Muscle mass',
  'masa.piel': 'Skin mass.',
  'masa.muscularPorcentaje': 'Percentage of muscle mass',
  'grasa.porcentaje': 'Percentage of fat',
  Peso: 'Weight',
  'Nombre de la clinica': 'Clinic name',
  'Nombre del equipo': 'Team name',
  'Nombre del nutriólogo': 'Nutritionist name',
  Dirección: 'Address',
  'menu.footer': 'Footer',
  'Teléfono de contacto': 'Contact phone',
  'placeholder.nombre': 'Name',
  'placeholder.apellido': 'Last name',
  'placeholder.email': 'Email',
  'menu.description': 'Preview',
  'placeholder.direccion': 'Address',
  'datosActividadFisica.actividadPosicion': 'Activity and position if applicable',
  'datosActividadFisica.equipo': 'Team (if applies)',
  'datosActividadFisica.numero': 'Number (if applies)',
  'datosActividadFisica.ejercicio': 'Exercise',
  'datosActividadFisica.objetivoDeportivo': 'Sport objective / Objective of the consultation',

  'medicion.anterior': 'previous',
  'medicion.actual': 'current',
  'medicion.fechaMedicion': 'Measurement date',
  'medicion.masaEsqueletica': 'Masa esqueletica',
  'medicion.tipoMedicion': 'Measurement type',
  'medicion.creadaPor': 'Registred by',
  'medicion.datosBasicos': 'Basic data',
  'medicion.seMidioEl': 'Measured at',
  'medicion.diametros': 'Diameters',
  'medicion.perimetros': 'Perimeters',
  'medicion.pliegues': 'Skinfolds',
  'medicion.acciones': 'Actions',
  'medicion.formulasCalculo': 'Presentation calculation formulas',
  'medicion.somatotipo': 'Somatotype',
  'medicion.indiceMusculoOseo': 'Bone-muscle index',
  'medicion.indices': 'Indexes',
  'medicion.resumenCalculo': 'Calculation summary',
  'medicion.crearNuevaMedicion': 'Create a new measurement',
  'medicion.medicionesAnteriores': 'There are previous measurements',
  'medicion.medicionesAnterioresMensaje':
    'If you wish you can copy all the values from a previous session, and only modify the ones you need',
  'medicion.appointment': 'Next appointment',

  'fechaMedicion.fecha': 'Date',
  'tipoMedicion.numeroComponentes': 'Number of components',
  'datosBasicos.peso': 'Weight',
  'datosBasicos.alturaDePie': 'Stretch stature',
  'datosBasicos.alturaSentado': 'Sitting height',
  'diametros.envergadura': 'Arm span',
  'diametros.biacromial': 'Biacromial breadth',
  'diametros.toraxTransverso': 'Transverse chest breadth',
  'diametros.toraxAnteroPosterior': 'Antero-posterior chest',
  'diametros.biiliocrestideo': 'Biiliocristal breadth',
  'diametros.humeralBiepicondilar': 'Humeral biepicondylar',
  'diametros.femoralBiepicondilar': 'Femoral biepicondylar',
  'diametros.munecaBioestiloideo': 'Wrist bi-styloid breadth',
  'diametros.tobilloBimaleolar': 'Bimalleolar breadth',
  'diametros.humeral': 'Humerus breadth',
  'diametros.muneca': 'Wrist girth',
  'perimetros.cabeza': 'Head girth',
  'perimetros.brazoRelajado': 'Arm relaxed girth',
  'perimetros.brazoFlexionado': 'Arm flexed and tensed girth',
  'perimetros.antebrazoMaximo': 'Forearm girth',
  'perimetros.muneca': 'Wrist girth',
  'perimetros.toraxMesoesternal': 'Mesoesternal chest',
  'perimetros.cinturaMinima': 'Waist girth',
  'perimetros.caderaMaxima': 'Hips girth',
  'perimetros.musloMaximo': 'Thigh 1 cm gluteal',
  'perimetros.musloMedio': 'Thigh middle girth',
  'perimetros.pantorrillaMaxima': 'Calf girth',
  'pliegues.triceps': 'Triceps skinfold',
  'pliegues.subescapular': 'Subscapular skinfold',
  'pliegues.biceps': 'Biceps skinfold',
  'pliegues.crestaIliaca': 'Iliac crest skinfold',
  'pliegues.supraespinal': 'Supraspinal skinfold',
  'pliegues.abdominal': 'Abdominal skinfold',
  'pliegues.musloFrontal': 'Thigh skinfold',
  'pliegues.pantorrillaMaxima': 'Maximum calf',
  'pliegues.pantorrilla': 'Calf skinfold',
  'pliegues.sumatoria': 'Sum of skinfolds in millimeters',
  'pliegues.sumatoriaDe6Pliegues': '6 skinfold Sum',
  'pliegues.sumatoriaDe8Pliegues': '8 skinfold Sum',
  'formulasCalculo.grasas': 'Fat',
  'formulasCalculo.musculo': 'Muscle',
  'somatotipo.endomorfo': 'Endomorph',
  'somatotipo.ectomorfo': 'Ectomorph',
  'somatotipo.mesomorfo': 'Mesomorph',
  'somatotipo.X': 'Coord X',
  'somatotipo.Y': 'Coord Y',
  'indiceMusculoOseo.valor': 'Value',
  'resumenCalculo.edad': 'Age',
  'resumenCalculo.genero': 'Gender',
  'resumenCalculo.porcentajeDeGrasaElegido': 'Chosen fat percentage',
  'resumenCalculo.porcentajeDeMusculoElegido': 'Close muscle percentage',
  'resumenCalculo.masaReajustadaPiel': 'Readjusted mass skin',
  'resumenCalculo.masaReajustadaAdiposa': 'Readjusted adipose mass',
  'resumenCalculo.masaReajustadaMuscular': 'Readjusted muscle mass',
  'resumenCalculo.masaReajustadaResidual': 'Readjusted residual mass',
  'resumenCalculo.masaReajustadaOsea': 'Readjusted bone mass',
  'resumenCalculo.indiceAdiposo': 'Adipose index',
  'resumenCalculo.indiceMuscular': 'Muscle index',
  'resumenCalculo.indiceResidual': 'Residual index',
  'resumenCalculo.indiceOsea': 'Bone index',
  'resumenCalculo.indicePiel': 'Skin index',
  'resumenCalculo.indiceMusculoLastre': 'Muscle ballast index',
  'resumenCalculo.zScoreAdiposa': 'Adipose Z score',
  'resumenCalculo.zScoreMuscular': 'Muscular Z score',
  'resumenCalculo.zScoreResidual': 'Residual Z score',
  'resumenCalculo.zScoreCabeza': 'Head Z score',
  'resumenCalculo.zScoreOsea': 'Bone Z score',

  'peso.pesoIdeal': 'Ideal reference weight in kilograms',

  'perfilReferencia.nombre': 'Name',
  'perfilReferencia.edad': 'Age',
  'perfilReferencia.genero': 'Gender',
  'perfilReferencia.deporteActividad': 'Sport/Activity',
  'perfilReferencia.crear': 'Create a new reference profile',
  'perfilReferencia.seleccionar': 'Select a reference profile',
  'perfilReferencia.tolerencia': 'Tolerance of',
  'perfilReferencia.indiceMusculoOseo': 'Mucle-Bone Index',
  'sumatoria.de.6.pliegues': 'Sum of 6 skinfolds',

  'placeholder.celular': 'Cell phone',
  'placeholder.recomendadoPor': 'Write the name of the person who recommended him/her',
  'placeholder.relatoAlimentacion': 'Write a patient feeding day here',
  'placeholder.problemasSalud': 'Write here if the patient has health problems',
  'placeholder.suplementos': 'Write here if the patient consumes supplements',
  'placeholder.hidratacion': 'Write here how the patient hydrates himself',
  'placeholder.observaciones': 'Write here any additional observations',
  'placeholder.ejercicio':
    'Describe here the physical activity of a patient, the frequency, intensity, duration and other data that you consider necessary',
  'placeholder.objetivoDeportivo':
    'Write down the date and the different objectives to work in the next period',
  'placeholder.nombreGrupo': 'Write the name of the group',

  'buttons.share': 'Send to patient app',
  'buttons.download': 'Download',
  'buttons.regresar': 'Back',

  'buttons.compararSesion': 'Compare session',
  'buttons.regresarAlDetalleDeLaSesion': 'Back to session detail',

  'presentationComponents.sumatoriaPlieguesTabla': 'CURRENT FOLDS SUM (MM)',

  'URL del libro de equivalentes en inglés': 'URL of the book of equivalents in English',
  'URL del libro de equivalentes en español': 'URL of the book of equivalents in Spanish',

  alimento: 'Food',
  Kcal: 'Kcal',
  Proteinas: 'Proteins',
  Carbohidratos: 'Carbohydrates',
  Grasas: 'Fats',

  'alimento.cereal': 'Grains',
  'alimento.grasa': 'Fat',
  'alimento.proteina': 'Protein',
  'alimento.proteinaOtro': 'Protein (other)',
  'alimento.lácteos': 'Dairy',
  'alimento.lácteosOtro': 'Dairy (other)',
  'alimento.verdura': 'Vegetable',
  'alimento.fruta': 'Fruit',
  'alimento.protEnPolvoConCarb': 'Protein in powder with carbohydrates',
  'alimento.protEnPolvoSinCarb': 'Protein in powder without carbohydrates',
  'alimento.bebidaDeportiva': 'Sports drink',
  'alimento.gel': 'Gel',
  'reference.ViewButton': 'Preview',
  'reference.ExamplesEs': 'Regionalized equivalencies in Spanish',
  'reference.ExamplesEn': 'Regionalized equivalencies in English',

  '5 Componentes': '5 Components',
  '2 Componentes': '2 Components',
  'Perdida de grasa': 'Fat loss',

  'Regresar al paciente': 'Back to patient',
  'medicion.5Componentes': '5 Components',
  'Calculos y resultados antropométricos': 'Calculations and anthropometric results',
  Componente: 'Component',
  Kilogramos: 'Kilograms',
  Porcentaje: 'Percentage',

  'buttons.lostWeight': 'Weight Loss',
  'value.masaMuscularTitle': 'MUSCLE MASS',

  'Numero de pliegues': 'Number of skinfolds',
  Sumatoria: 'Sum',

  '3 pliegues para Lohmann': '3 skinfolds for Lohmann',

  '3 Pliegues (Triceps)': '3 skinfolds (Triceps)',

  '3 Pliegues (Abdominal)': '3 skinfolds (Abdominal)',

  '4 Pliegues': '4 skinfolds',

  '6 Pliegues': '6 skinfolds',

  '8 Pliegues': '8 skinfolds',

  'medicion.estimacionDelDescensoDeGrasa': 'Fat loss estimation',
  'medicion.estimacionDelIncrementoDeMusculo': 'Estimation of muscle gain',

  'Porcentaje de grasa': 'Fat percentage',
  '(Ecuaciones de regresión)': '(Regression equations)',
  'medicion.sumatoriasDePliegues': 'Skinfold sums',

  'Calculos y': 'Calculations and',
  resultados: 'results',
  antropométricos: 'anthropometric',
  'Masa adiposa': 'Adipose mass',
  '(5 componentes)': '(5 components)',
  'Masa grasa': 'Fat mass',
  'Mostrar porcentaje de grasa en presentación': 'Show fat percentage in presentation',

  'value.masaAdiposa': 'Adipose mass',
  'value.maximo': 'Maximum',

  'buttons.sendWelcomeEmail': 'Send welcome email',
  'buttons.generatePassword': 'Generate password',
  'presentationComponents.sumatoriaPlieguesReferenciaTabla': 'MAX FOLDS SUM (MM)',

  'presentationComponents.masaAdiposa': 'ADIPOSE MASS',

  'presentationComponents.evaluacionAntropometrica': 'ANTHROPOMETRIC EVALUATION',
  'presentationComponents.fechaEvaluacion': 'Evaluation date',
  'presentationComponents.posicion': 'Position',
  'presentationComponents.grasaCorporal': 'BODY FAT',
  'presentationComponents.porcentajeGrasa': '% FAT',
  'presentationComponents.sumatoriaPliegues': 'SKINFOLDS SUM (mm)',
  'presentationComponents.referencia': 'REFERENCE (mm)',
  'presentationComponents.referenciaKgs': 'REFERENCE (kg)',
  'presentationComponents.sumatoriaPlieguesTitulo': 'SKINFOLDS SUM',
  'presentationComponents.indiceMusculoOseoTitulo': 'MUSCLE-BONE INDEX',
  'presentationComponents.diametrosTitulo': 'DIAMETERS',
  'presentationComponents.circunferenciasTitulo': 'CIRCUMFERENCES',
  'presentationComponents.plieguesTitulo': 'SKINFOLDS',
  'presentationComponents.masaMuscularTitulo': 'MUSCLE MASS',
  'presentationComponents.masaOsea': 'Bone mass',
  'presentationComponents.masaOseaTitulo': 'BONE MASS',
  'presentationComponents.porcentajeMasaOseaTitulo': 'BONE MASS PERCENTAGE',
  'presentationComponents.valorPorcentajeMasaOsea': 'Bone mass',
  'presentationComponents.valorKilosMasaOsea': 'Bone mass',
  'presentationComponents.sumatoriaPlieguesActual': 'CURRENT SKINFOLDS SUM (mm)',
  'presentationComponents.sumatoriaPlieguesMaxima': 'MAXIMUM SKINFOLDS SUM (mm)',
  'presentationComponents.masaMuscularKgs': 'MUSCLE MASS (kg)',
  'presentationComponents.masaMuscularKgsReferencia': 'MUSCLE MASS (kg) REFERENCE',
  'presentationComponents.observaciones': 'Observations',
  'presentationComponents.necesitasBajar': 'You need to lose',
  'presentationComponents.necesitasSubir': 'You need to gain',

  'tableHeader.patientName': "Patient's name",
  'tableHeader.patientEmail': 'Email',
  'tableHeader.sport': 'Sport',
  'tableHeader.position': 'Position',
  'tableHeader.cellPhone': 'Cell phone number',
  'tableHeader.actions': 'Actions',
  'tableHeader.clientName': "Client's name",
  'tableHeader.clientEmail': 'Email',
  'tableHeader.serviceEndDate': 'End of service date',
  'tableHeader.nombrePerfilReferencia': 'Reference profile name',
  'tableHeader.edadPerfilReferencia': 'Age',
  'tableHeader.generoPerfilReferencia': 'Gender',
  'tableHeader.deportePerfilReferencia': 'Sport/Activity',
  'tableHeader.fechaActualizacionPerfilReferencia': 'Update date',
  'tableHeader.acciones': 'Actions',
  'tableHeader.archivoReferenciaNombre': 'Reference file name',
  'tableHeader.archivoNombre': 'File name',
  'tableHeader.visibility': 'Visibility',
  'tableHeader.createdAt': 'Upload date',
  'tableHeader.groupName': 'Name',
  'tableHeader.membersNumber': '# of Members',
  'tableHeader.groupActions': 'Actions',
  'tableHeader.number': 'Number',
  'tableHeader.email': 'Email',

  'topBar.issuesHistory': 'Issues history',
  'topBar.projectManagement': 'Project management',
  'topBar.typeToSearch': 'Type to search...',
  'topBar.buyNow': 'Buy for $24',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hi',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Role',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Phone',
  'topBar.profileMenu.editProfile': 'Edit profile',
  'topBar.profileMenu.editPassword': 'Edit password',
  'topBar.profileMenu.logout': 'Logout',

  'value.sumatoriaPliegues': 'Skinfolds sum',
  'value.porcentajeGrasa': 'Fat percentage',
  'value.masaMuscular': 'Muscle mass',
  'value.edad': 'Age',
  'value.equipo': 'Team',
  'value.deportePosicion': 'Sport and/or position',
  'value.peso': 'Weight',
  'value.altura': 'Height',
  'value.actual': 'Current',
  'value.ideal': 'Ideal',
  'value.referencia': 'Reference',
  'value.porcentaje': 'Percentage',
  'value.years': 'years old',
  'value.of': 'of',
  'value.anos': 'years old',

  'filterReport.edad': 'Age',
  'filterReport.equipo': 'Category',
  'filterReport.genero': 'Gender',
  'filterReport.posicion': 'Position',
  'filterReport.medida': 'Variable',
  'filter.male': 'Male',
  'filter.female': 'Female',
  'filter.delete': 'Delete',
  'filter.add': 'Add filter',
  'filter.addAge': 'Add age',
  'filter.addTeam': 'Add team',
  'filter.addGender': 'Add gender',
  'filter.addPosition': 'Add position',
  'filter.addVariable': 'Add variable',

  'filterReportSelected.edad': 'Age selected:',
  'filterReportSelected.equipo': 'Team selected:',
  'filterReportSelected.genero': 'Gender selected:',
  'filterReportSelected.poscion': 'Position selected:',
  'filterReportSelected.medida': 'Variable selected:',

  'bio.prealbumina': 'Prealbumina',
  'bio.albumin': 'Albumin',
  'bio.totalProteins': 'Total proteins',
  'bio.urea': 'Urea',
  'bio.totalCholesterol': 'Total cholesterol',
  'bio.hdlCholesterol': 'HDL cholesterol',
  'bio.ldlCholesterol': 'LDL cholesterol',
  'bio.triglycerides': 'Triglycerides',
  'bio.glucose': 'Glucose',
  'bio.sodium': 'Sodium',
  'bio.potassium': 'Potassium',
  'bio.chlorine': 'Chlorine',
  'bio.magnesium': 'Magnesium',
  'bio.calcium': 'Calcium',
  'bio.serumIron': 'Serum iron',
  'bio.ferritin': 'Ferritin',
  'bio.transferrin': 'Transferrin',
  'bio.hemoglobin': 'Hemoglobin',
  'bio.hematocrito': 'Hematocrito',
  'bio.vcm': 'VCM',
  'bio.leukocytes': 'Leukocytes',
  'bio.lymphocytes': 'Lymphocytes',
  'bio.alt': 'ALT',
  'bio.ast': 'AST',
  'bio.creatinine': 'Creatinine',
  'bio.uricAcid': 'Uric acid',
  'bio.totalBilirubin': 'Total bilirubin',
  'bio.platelets': 'Platelets',
  'bio.glomerularFilteringRate': 'Glomerular filtering rate',
  'bio.testosterone': 'Testosterone',
  'bio.cortisolInFasting': 'Cortisol in fasting',

  'tablaProgresoSection.grasa': 'Body fat',
  'tablaProgresoSection.musculo': 'Muscle mass',
  'tablaProgresoSection.basicos': 'Basic',
  'tablaProgresoHeader.masaAdiposaSesion': 'current skinfold sum (mm)',
  'tablaProgresoHeader.masaAdiposaMax': 'Max Adipose Mass (kg)',
  'tablaProgresoHeader.masaAdiposaActual': 'Actual Adipose Mass (kg)',
  'tablaProgresoHeader.masaAdiposaDif': 'Fat to lose (kg)',
  'tablaProgresoHeader.masaMusculoSesion': 'Actual Muscle Mass (kg)',
  'tablaProgresoHeader.masaMusculoRef': 'Reference Muscle Mass (kg)',
  'tablaProgresoHeader.masaMusculoDif': 'Muscle mass to gain (kg)',
  'tablaProgresoHeader.peso': 'Weight (kg)',
  'tablaProgresoHeader.altura': 'Height (cm)',
  'gridProgressReport.tittle': 'Progress Report',

  'tablaProgresoHeader.Categoria': 'Category',
  'tablaProgresoHeader.Grasa': 'Body fat skinfold sum',
  'tablaProgresoHeader.musculo': 'Muscle mass',
  'tablaProgresoHeader.basicos': 'Weight y Height',

  'tablaProgresoSesionHeader.al': 'to',
  'tablaProgresoSesionHeader.de': 'of',
  'tablaProgresoSesionHeader.Nombre': 'Name',

  'menuData.FirmaDelUsuario': 'Upload signature',
  'menuData.DeleteFirmaDelUsuario': 'Delete signature',
  'menuData.SelectorDeArchivo': 'Upload logo',
  'menuData.DeleteSelectorDeArchivo': 'Delete logo',

  'prefilReferencia.medicion': 'Measure',
  'prefilReferencia.valor': 'Value',
  'prefilReferencia.tolerancia': 'Tolerance',
  'prefilReferencia.datosBasicos': 'Basic data',
  'prefilReferencia.diametros': 'Diameters',
  'prefilReferencia.perimetros': 'Perimeters',
  'prefilReferencia.pliegues': 'Skinfolds',
  'prefilReferencia.otros': 'Others',
  'prefilReferencia.resultados': 'Results',
  'medidas.cm': ' (cm)',
  'medidas.mm': ' (mm)',
  'medidas.kg': ' (kg)',
  'medidas.ecuacion': ' (ecuation)',

  '2componentes.formula': 'Formule',
  '2componentes.selectInvest': 'Select investigator',
  '2componentes.grasaDeseado': 'Ideal Fat Percentage',
  '2componentes.disminuir': 'Reduce',
  '2componentes.6plieguesDeseado': 'Ideal 6 skinfolds Sum',
  '2componentes.mmDePliegues': 'mm of skinfolds',

  'portions.Cereales': 'Cereal: ',
  'portions.Grasas': 'Fat: ',
  'portions.Proteinas': 'Protein: ',
  'portions.Lacteos': 'Milk: ',
  'portions.Vegetales': 'Vegetable: ',
  'portions.Frutas': 'Fruit: ',

  'tablaProgreso.Cabeza': 'Head girth (cm)',
  'tablaProgreso.BrazoRelajado': 'Brazo relajado (cm)',

  'reportsStepHeader.1': 'Report type',
  'reportsStepHeader.2': 'Select patients',
  'reportsStepHeader.3': 'Select measures',
  'reportsStepHeader.4': 'Report result',
  'reportsStep.Date': '¿ When ?',
  'messageConfirm.SelectedDate': 'Date selected from:',
  'messageConfirm.At': 'at',
  'ReportHeader.ComparacionGrasa': 'Fat comparison',
  'ReportHeader.ComparacionMuscle': 'Muscle comparison',

  'pieReport.optima': 'optimal',
  'pieReport.deficiente': 'deficient',
  'pieReport.excesiva': 'excessive',
  'pieReport.JugadoresOptimos': 'Optimal',
  'pieReport.JugadoresDeficientes': 'Deficient',
  'pieReport.JugadoresExcesiva': 'Excessive',
  'pieReport.JugadoresComparados': 'Players compared',
  'pieReport.JugadoresConGrasa': 'Players with fat mass',
  'pieReport.JugadoresConMuscular': 'Players with muscular mass',

  'LinesReport.Referencia': 'Reference',
  'LinesReport.Paciente': 'Patient',
  'LinesReport.Peso': 'Weigth',
  'LinesReport.Medida': 'measure',
  'LinesReport.AlturaDePie': 'Stretch stature',

  'tabPaciente.SelectorMedidas': 'Anthropometric',
  'tabPaciente.SelectorAntropo': 'measurements',

  'buttonPatient.recordatorio': 'Send portion app reminder',
  'placeHolder.SelectorMes': 'Select a month',

  'patientSessionIndice.IMC': 'Body mass index (BMI)',
  'patientSessionIndice.cintura': 'Waist Hip Index',
  'patientSessionIndice.musculo': 'Muscle / Osseous Index (using Martin ecuations)',
  'patientSessionIndice.adiposoMuscular': 'Adipose Muscular Index',
  'patientSessionIndice.cormico': 'Cormic Index (%)',
  'patientSessionIndice.adiposo': 'Adipose Index',
  'patientSessionIndice.muscular': 'Muscle mass Index',
  'patientSessionIndice.residual': 'Residual Index',
  'patientSessionIndice.oseo': 'Osseous',
  'patientSessionIndice.piel': 'Skin Index',
  'patientSessionIndice.musculoOseo': 'Muscle Osseous Index',
  'patientSessionIndice.musculoLastre': 'Ballast Muscle Index',
  'patientSessionIndice.lastre': 'Ballast Index',

  'alert.sinPerfil': 'Add reference profile, to continue.',
  'appointment.selectDate': 'Select next date appointment',
  'appointment.selectDuration': 'Select appointment duration',
  'patientConfiguration.activateIntenseworkout': 'Activate intense workout day',
  'patientConfiguration.activateRestDay': 'Activate rest day',
  'patientConfiguration.intenseworkout': 'Intense workout',
  'patientConfiguration.ligtheworkout': 'Ligth workout',
  'patientConfiguration.restworkout': 'Rest workout',
  'patientConfiguration.equivalentButton': 'Configure equivalents',

  'fatLossEstimation.talla': 'Size (cm)',
  'fatLossEstimation.objetivoPliegues': '6 skinfolds sum Objective',
  'fatLossEstimation.zAdiposa': 'Z-adipose',
  'fatLossEstimation.maxAdipose': 'Max adipose Mass Kg',
  'fatLossEstimation.masaAdiposaActual': 'Actual Adipose Mass Kg',
  'fatLossEstimation.masaAdiposaDif': 'Adipose Mass diference:',

  'muscleMassEstimation.osseousMass': 'Osseous Mass Kg',
  'muscleMassEstimation.indiceObjetivo': 'obj. Index M/O',
  'muscleMassEstimation.muscularMassActual': 'Actual Muscular Mass Kg',
  'muscleMassEstimation.muscularMassRef': 'Reference Muscular Mass',
  'muscleMassEstimation.muscularMassDif': 'Muscular Mass diference:',

  'reportType.Tabla': 'Table',
  'reportType.Pie': 'Pie',
  'reportType.Puntos': 'Lines',

};

export default {
  locale: 'en-US',
  antdData,
  localeData,
  messages,
};
