import antdData from 'antd/lib/locale-provider/es_ES';
import localeData from 'react-intl/locale-data/es';
import 'moment/locale/es';

const messages = {
  'sumatoria.de.6.pliegues': 'Sumatoria de 6 pliegues',

  pacientes: 'Pacientes',
  'MUSCLE (KG)': 'MÚSCULO (KG)',
  'categorias.equipos': 'Categorias / Equipos',
  'mi.club': 'Mi club',
  'configuracion.de.usuarios': 'Configuración de usuarios',
  'menuPrincipal.Documentos': 'Documentos ',

  'football.description':
    'El trabajar con futbolistas de alto rendimiendo es una tarea compleja, es por ello que te brindamos este sistema que mejorará la forma en como lo hacías. El objetivo de este sistema es brindarle al equipo de nutrición una herramienta donde puedan: crear expedientes completos de los jugadores, calcular planes de alimentación, monitorear los cambios en la composición corporal tanto por 5 componentes así como por 2 componentes  dependiendo de los objetivos deseados, comparar de manera global el estado nutricional y físico del equipo. Teniendo la ventaja de proporcionar a los directivos del equipo, la información en tiempo real de cómo se encuentran los jugadores, para crear estrategias de mejora en caso necesario.',
  'football.title': 'Football teams',

  'welcome.nutriologo':
    'Al ser un Nutriólogo Certificado en Nutrición Deportiva, te brindamos un sistema que tiene como objetivo para ti: optimizar los expedientes de tus pacientes, generar una base de datos del progreso de tus pacientes, crear gráficos que ayudarán al paciente a comprender de manera visual el camino de su progreso y sobre todo, otorgar al paciente una herramienta que le ayudará a registrar su plan de alimentación y monitorear sus avances, todo a través de su celular, generando un acompañamiento más allá de la consulta.',

  'alert.campoVacio': 'Este campo no puede quedar vacío',
  'alert.borrarElemento': '¿Quieres eliminar este elemento?',
  'alert.borrarElementoMensaje': 'Puedes borrar datos del pasado relacionados a este elemento',
  'alert.quitarElemento': '¿Quieres quitar este elemento?',
  'alert.quitarElementoMensaje': 'Esta acción no elimina el elemento',
  'alert.campoRequerido': 'Este campo es requerido',
  'alert.campoImportante':
    'Este campo es importante para llevar el seguimiento y calcular la edad del paciente en el momento de la medición',
  'alert.nombreVacio': 'El nombre no puede quedar vacio',
  'alert.emailVacio': 'El email no puede quedar vacio',
  'alert.contrasenaVacia': 'La contraseña no puede quedar vacia',
  'alert.fechaNacimientoVacia': 'La fecha de nacimiento no puede quedar vacia',
  'alert.edadVacia': 'La edad no puede quedar vacia',
  'alert.deporteVacio': 'El deporte/actividad no puede quedar vacio',
  'alert.indiceMusculoOseo': 'Indice Músculo Óseo no puede quedar vacio',
  'alert.sumatoriaDe6Pliegues': 'Sumatoria de 6 pliegues no puede quedar vacio',

  'archivoReferencia.crear': 'Crea un nuevo archivo de referencia',
  'archivoReferencia.paraQuienSeraVisible': '¿Para quien será visible estos archivos?',
  'archivoReferencia.descripcion': 'Sube un archivo y compártelo con tu paciente',

  'archivoReferencia.visibilidad.soloYo': 'Sólo yo',
  'archivoReferencia.visibilidad.nutriologosPacientes': 'Nutriólogos y pacientes',
  'archivoReferencia.visibilidad.soloNutriologos': 'Sólo nutriólogos',
  'archivoReferencia.visibilidad.soloPacientes': 'Sólo pacientes',
  'archivoReferencia.cargarArchivo': 'Carga del archivo',
  'archivoReferencia.subirPDF': 'Haz click o arrastra un PDF a esta área',
  'archivoReferencia.subirPDFMensaje':
    'Cuando se suba el archivo puedes modificar la privacidad para que solo lo pueda ver ó el especialista ó el paciente ó ambos',
  'archivoReferencia.examinar': 'Examinar',
  'archivoReferencia.cargaExitosa': 'se cargó de forma correcta',
  'archivoReferencia.falloCarga': 'Falló la carga de',

  'bodyMass.adiposa': 'Adiposa',
  'bodyMass.muscular': 'Muscular',
  'bodyMass.residual': 'Residual',
  'bodyMass.osea': 'Ósea',
  'bodyMass.piel': 'Piel',

  'This browser is not compatible': 'Este navegador no es compatible',
  'Your browser is not compatible with our website. Please download Chrome.':
    'Tu navegador no es compatible con nuestro sitio web. Por favor descarga Chrome.',
  'Change zoom: ': 'Cambiar zoom: ',
  'To unblock, create a new session': 'Para desbloquear, crea una nueva sesión',
  'App blocked': 'App bloqueada',
  'Block App': 'Bloquear App',
  'Compare with': 'Comparar con',
  Nutritionists: 'Nutricionistas',
  'For nutritionists certified in Sports Nutrition':
    'Para nutricionistas certificados en Nutrición Deportiva',
  Login: 'Iniciar sesión',

  'Nutrition and Sports Centers': 'Centros de nutrición y deportes',
  'Program for a team of nutritionists certified in sports nutrition focused on promoting a healthier life':
    'Programa para un equipo de nutricionistas certificados en nutrición deportiva enfocada en promover una vida más saludable',

  'Football teams': 'Equipos de futbol',
  'For professional soccer teams and their staff of nutritionists certified in sports nutrition':
    'Para equipos de fútbol profesionales y su personal de nutricionistas certificados en nutrición deportiva',

  'buttons.regresarAlDetalleDeLaSesion': 'Regresar al detalle de la sesión',
  'buttons.compararSesion': 'Comparar sesión',

  'buttons.regresar': 'Regresar',
  'buttons.remove': 'Quitar',
  'buttons.view': 'Ver detalles',
  'buttons.cancel': 'Cancelar',
  'buttons.add': 'Agregar',
  'buttons.save': 'Guardar cambios',
  'buttons.saveClose': 'Guardar y cerrar',
  'buttons.measureGrowth': 'Medir crecimiento',
  'buttons.noMeasureGrowth': 'No medir',
  'buttons.fiveComponents': '5 Componentes',
  'buttons.twoComponents': '2 Componentes',
  'buttons.lostWeight': 'Perdida de grasa',
  'buttons.highPerformance': 'Alto rendimiento',
  'buttons.weightControl': 'Control de la composición corporal',
  'buttons.seeResults': 'Ver resultados',
  'buttons.print': 'Imprimir',
  'buttons.download': 'Descarga este archivo',
  'buttons.share': 'Enviar al paciente',
  'buttons.delete': 'Borrar',
  'buttons.create': 'Crear',
  'buttons.close': 'Cerrar',
  'buttons.addMeasurement': 'Agregar medición',
  'buttons.copyValuesFrom': 'Copiar valores desde...',
  'buttons.removeFromGroup': 'Quitar del grupo',
  'buttons.siguiente': 'Siguiente',
  'buttons.terminar': 'Terminar',

  'calendario.citasDe': 'Citas de',

  'chartTitle.massKg': 'Masa (KG)',
  'chartTitle.weight': 'Peso (KG)',
  'chartTitle.muscleMass': 'Masa muscular (KG)',
  'chartTitle.fatMass': 'Masa adiposa (KG)',
  'chartTitle.foldsSum': 'Sumatoria de pliegues (mm)',

  'client.createClient': 'Crea un nuevo cliente',

  'groups.createGroup': 'Crear una nueva categoría',
  'groups.addMembers': 'Agregar pacientes al grupo',

  'gender.male': 'Hombre',
  'gender.female': 'Mujer',

  'general.lastVisit': 'Ultima consulta',

  'breadcrumb.home': 'Inicio',

  'header.detallesSesion': 'Detalles de la sesión',

  'imageAlt.graficaCuerpo': 'Gráfica de un cuerpo',
  'imageAlt.graficaNino': 'Gráfica de un niño',
  'imageAlt.graficaNina': 'Gráfica de una niña',
  'imageAlt.logoClinica': 'Logo de la clínica',

  'labData.dato': 'Dato',
  'labData.medicion': 'Medición',
  'labData.diagnosticoBioquimicos': 'Diagnóstico de los biquímicos',
  'labData.potasio': 'Potasio',
  'labData.cloro': 'Cloro',
  'labData.bicarbonato': 'Bicarbonato Co2',
  'labData.glucosa': 'Glucosa',
  'labData.urea': 'Urea (Bun)',
  'labData.creatinina': 'Creatinina',
  'labData.gfrOther': 'Gfr Est-other',
  'labData.gfrAfricanamerican': 'Gfr Est-african American',
  'labData.calcio': 'Calcio',
  'labData.proteinaTotal': 'Proteína Total',
  'labData.albumina': 'Albúmina',
  'labData.bilirrubinaTotal': 'Bilirrubina Total',
  'labData.fosfatoAlcalino': 'Fosfato Alcalino',
  'labData.ast': 'AST',
  'labData.alt': 'ALT',
  'labData.wbc': 'Wbc',
  'labData.rbc': 'Rbc',
  'labData.hemoglobina': 'Hemoglobina',
  'labData.hematrocito': 'Hematrocito',
  'labData.mcv': 'MCV',
  'labData.mch': 'MCH',
  'labData.mchc': 'Mchc',
  'labData.rdw': 'Rdw',
  'labData.plaquetas': 'Plaquetas',
  'labData.colesterol': 'Colesterol',
  'labData.cortisol': 'Cortisol',
  'labData.cortisolTestosterona': 'Cortisol/Testosterona',
  'labData.testosteronaTotal': 'Testosterona total',
  'labData.testosteronaLibre': 'Testosterona libre',
  'labData.trigliceridos': 'Triglicéridos',
  'labData.hdl': 'Hdl Colesterol',
  'labData.ldl': 'Ldl Colesterol',
  'labData.colesterolHdl': 'Colesterol/Hdl/',
  'labData.rubellaIgg': 'Rubella Igg',
  'labData.hemoglobinaA1c': 'Hemoglobina A1c',
  'labData.crpUltraquant': 'Crp-ultraquant',
  'labData.ferritina': 'Ferritina',
  'labData.vitaminaD': 'Vitamina D, 25-hidroxi',

  'massType.muscle': 'Muscular',
  'massType.fat': 'Adiposa',

  'menu.inicio': 'Inicio',
  'menu.torneos': 'Torneos',
  'menu.sesionesDeMedicion': 'Sesiones de medición',
  'menu.reportes': 'Reportes',
  'menu.entry': 'Entrada',
  'menu.usuarios': 'Nutriólogo',
  'menu.image': 'Logo superior izquierdo',
  'menu.data': 'Logo superior derecho',
  'menu.footer': 'Pie de página',
  'menu.description': 'Reporte de resultados',
  'menu.interesados': 'Usuarios de solo lectura',

  'menu.calculo.equivalentes': 'Configuración de porciones equivalentes',

  'table.date': 'Fecha',
  'table.training': 'Tipo de entrenamiento',
  'table.water': 'Agua tomada',
  'table.activity': 'Actividad',
  'table.portions': 'Porciones',

  'menu.pacientes': 'Pacientes',
  'menu.sesions': 'Sesiones',
  'menu.jugadores': 'Jugadores',

  'menu.sesiones': 'Sesiones',
  'menu.schedule': 'Citas',
  'menu.perfilesDeReferencia': 'Perfiles de referencia',
  'menu.archivosDeReferencia': 'Documentos',
  'menu.clientes': 'Clientes',
  'menu.equipos': 'Categorias',
  'menu.alimentosYPorciones': 'Alimentos y porciones',
  'menu.dashboard': 'Dashboard',
  'menu.myGroups': 'Mis categorías',
  'menu.myClinic': 'Mi clínica',
  'menu.myProfile': 'Mi perfil',
  'menu.myTeam': 'Mi equipo/club',
  'menu.groupName': 'Nombre',
  'menu.groupMembers': 'Miembros',

  'user.name': 'Nombre',
  'user.photo': 'Foto',

  'search.placeholder.name': 'Ingresa un nombre',
  'search.placeholder.find': 'Escribe para empezar a buscar',
  'search.button': 'Buscar',

  'patientList.title': 'Lista de pacientes',

  'patientTab.progress': 'Progreso (gráfica)',
  'patientTab.progressTable': 'Progreso (tabla)',
  'patientTab.datosPaciente': 'Datos',
  'patientTab.medicionesResultados': 'Consultas',
  'patientTab.Growth': 'Crecimiento',
  'patientTab.calculoDietetico': 'Cálculo dietético',
  'patientTab.registroAlimentos': 'Registro de alimentos',
  'patientTab.archivosPaciente': 'Archivos',
  'patientTab.porcionesPaciente': 'Registro de alimentos',

  'patients.crear': 'Crea un nuevo paciente',
  'patients.nombre': 'Nombre',
  'patients.celular': 'Celular',
  'patients.fechaNacimiento': 'Fecha de nacimiento',
  'patients.tipoConsulta': 'Tipo de consulta',
  'patients.options.altoRendimiento': 'Alto rendimiento',
  'patients.options.controlPeso': 'Control de la composición corporal',
  'patients.controlCrecimiento': 'Control de crecimiento',
  'patients.options.medirCrecimiento': 'Medir crecimiento',
  'patients.options.noMedir': 'No medir',
  'patients.email': 'Email',
  'patients.deportePosicion': 'Deporte/Posición/Actividad',
  'patients.expediente': 'Expediente de',
  'patients.fechaUltimaSesion': 'Fecha de la última sesión',

  'calculoDietetico.metabolismoBasal': 'Metabolismo basal',
  'calculoDietetico.seleccionaInvestigador': 'Selecciona la ecuación',
  'calculoDietetico.kcalDiarias': 'Kcal diarias',
  'calculoDietetico.seleccionaNivelActividadPaciente':
    'Selecciona el nivel de actividad para calcular GET (OMS 1985)',
  'calculoDietetico.ignorarFactor': 'Ignorar Factor de Act.',
  'calculoDietetico.sedentario': 'Sedentario',
  'calculoDietetico.liviano': 'Liviano',
  'calculoDietetico.moderado': 'Moderado',
  'calculoDietetico.fuerte': 'Fuerte',
  'calculoDietetico.extremo': 'Extremo',
  'calculoDietetico.gastoEnergeticoTotal': 'Gasto energético total',
  'calculoDietetico.perdidaGrasa': 'Pérdida de grasa',
  'calculoDietetico.calculoDieta': 'Cálculo de la dieta',
  'calculoDietetico.numero': 'Número',
  'calculoDietetico.kcal': 'Kcal',
  'calculoDietetico.proteinas': 'Proteínas',
  'calculoDietetico.carbohidratos': 'Carbohidratos',
  'calculoDietetico.lipidos': 'Lípidos',
  'calculoDietetico.perdidaGrasaAumentoMusculo':
    'Pérdida de grasa -500Kcal, aumento de músculo +500Kcal (A criterio del nutriólogo)',
  'calculoDietetico.total': 'Total',

  'datosPaciente.datosBasicos': 'Datos básicos',
  'datosPaciente.bioquimicos': 'Bioquímicos',
  'datosPaciente.darAlta': 'Dar de alta',

  'datosCliente.contrasena': 'Contraseña',

  'datosBasicos.informacionPersonal': 'Información personal',
  'datosBasicos.informacionConsulta': 'Información de consulta',
  'datosBasicos.frecuenciaAlimentos':
    'Frecuencia de alimentos (número de veces por semana, de 0 a 7)',
  'datosBasicos.relatoAlimentacion': 'Relato de alimentación',
  'datosBasicos.datosSalud': 'Datos de salud',
  'datosBasicos.datosActividadFisica': 'Datos de actividad física',

  'modal.createTorneo': 'Crear un torneo',
  'modal.createSesion': 'Crear una sesión de medición',

  'form.name': 'Nombre',
  'form.date': 'Fecha',
  'form.torneoName': 'Nombre del torneo',
  'form.startDate': 'Fecha de inicio',
  'form.endDate': 'Fecha de fin',

  'informacionPersonal.nombre': 'Nombre',
  'informacionPersonal.email': 'Email',
  'informacionPersonal.celular': 'Celular',
  'informacionPersonal.url': 'Url',
  'informacionPersonal.recomendadoPor': 'Recomendado por',
  'informacionPersonal.controlCrecimiento': 'Control de crecimiento',
  'informacionPersonal.fechaNacimiento': 'Fecha de nacimiento',
  'informacionPersonal.genero': 'Género',
  'informacionPersonal.enviarPorEmail': 'Enviar por email',
  'informacionPersonal.indiceCrecimiento': 'Índice de crecimiento',
  'informacionPersonal.edadPHV': 'Edad PHV',
  'informacionPersonal.clasMasc': 'Clas masc',

  'informacionConsulta.numeroComponentes': 'Número de componentes',
  'informacionConsulta.tipoConsulta': 'Tipo de consulta',
  'informacionConsulta.perfilReferencia': 'Perfil de referencia',

  'frecuenciaAlimentos.lacteos': 'Lácteos',
  'frecuenciaAlimentos.carnesRojas': 'Carnes rojas',
  'frecuenciaAlimentos.pollo': 'Pollo',
  'frecuenciaAlimentos.pescadoMariscos': 'Pescado o mariscos',
  'frecuenciaAlimentos.fruta': 'Fruta',
  'frecuenciaAlimentos.verdura': 'Verdura',
  'frecuenciaAlimentos.huevo': 'Huevo',
  'frecuenciaAlimentos.panTortilla': 'Pan o tortilla',
  'frecuenciaAlimentos.arrozPasta': 'Arroz o pasta',
  'frecuenciaAlimentos.leguminosas': 'Leguminosas',
  'frecuenciaAlimentos.semillasOleaginosas': 'Semillas oleaginosas',
  'frecuenciaAlimentos.alcohol': 'Alcohol',
  'frecuenciaAlimentos.refrescos': 'Refrescos',
  'frecuenciaAlimentos.cafe': 'Café',
  'frecuenciaAlimentos.golosinas': 'Golosinas',
  'frecuenciaAlimentos.botanas': 'Botanas',
  'frecuenciaAlimentos.postres': 'Postres',
  'frecuenciaAlimentos.agua': 'Agua',
  'frecuenciaAlimentos.aguaLitroDia': 'Agua (Litros por día)',
  'frecuenciaAlimentos.lacteos.tooltip': 'Leche, Yogurt, Requeson, Queso, etc...',
  'frecuenciaAlimentos.carnesRojas.tooltip': 'Res, Cerdo, Pavo, etc...',
  'frecuenciaAlimentos.pollo.tooltip': ' ',
  'frecuenciaAlimentos.pescadoMariscos.tooltip': ' ',
  'frecuenciaAlimentos.fruta.tooltip': ' ',
  'frecuenciaAlimentos.verdura.tooltip': ' ',
  'frecuenciaAlimentos.huevo.tooltip': ' ',
  'frecuenciaAlimentos.panTortilla.tooltip': ' ',
  'frecuenciaAlimentos.arrozPasta.tooltip': ' ',
  'frecuenciaAlimentos.leguminosas.tooltip': 'Frijol, Lenteja, Soya, Garbanzos, etc...',
  'frecuenciaAlimentos.semillasOleaginosas.tooltip': 'Nueces, Almendras, Ajonjolí, etc...',
  'frecuenciaAlimentos.alcohol.tooltip': ' ',
  'frecuenciaAlimentos.refrescos.tooltip': ' ',
  'frecuenciaAlimentos.cafe.tooltip': ' ',
  'frecuenciaAlimentos.golosinas.tooltip': 'Dulces, paletas',
  'frecuenciaAlimentos.botanas.tooltip': ' ',
  'frecuenciaAlimentos.postres.tooltip': 'Pasteles, Malteadas, Galletas',
  'frecuenciaAlimentos.agua.tooltip': 'Agua (frecuencia)',
  'frecuenciaAlimentos.aguaLitroDia.tooltip': 'Agua (Litros por día)',

  'foodGroup.cereal': 'Cereal',
  'foodGroup.grasa': 'Grasa',
  'foodGroup.proteina': 'Proteína',
  'foodGroup.lecheDescremada': 'Lácteos',
  'foodGroup.quesos': 'Quesos',
  'foodGroup.verdura': 'Verdura',
  'foodGroup.fruta': 'Fruta',
  'foodGroup.proteinaPolvoConCarb': 'Prot en polvo c/carb',
  'foodGroup.proteinaPolvoSinCarb': 'Prot en polvo s/carb',
  'foodGroup.bebidaDeportiva': 'Bebida deportiva',
  'foodGroup.gel': 'Gel',
  'foodGroup.lacteos': 'Lácteos',
  'foodGroup.suplementos': 'Suplementos',

  'format.date': 'DD [de] MMMM [de] YYYY',
  'format.dateTime': 'DD [de] MMMM [de] YYYY [a las] HH:mm',

  'registroAlimentos.bitacoraAlimenticia': 'Bitácora alimenticia',
  'registroAlimentos.fecha': 'Fecha',
  'registroAlimentos.actividadFisica': 'Actividad física',
  'registroAlimentos.duracionMinutos': 'Duración en minutos',
  'registroAlimentos.litrosAgua': 'Litros de agua',
  'registroAlimentos.dia': 'Día',
  'registroAlimentos.consumidas': 'Consumidas',
  'registroAlimentos.total': 'Total',
  'registroAlimentos.desayuno': 'Desayuno',
  'registroAlimentos.colacion': 'Colación',
  'registroAlimentos.comida': 'Comida',
  'registroAlimentos.cena': 'Cena',

  'relatoAlimentacion.descripcion': 'Describe un día de tu alimentación',

  'datosSalud.problemasSalud': 'Problemas de salud',
  'datosSalud.suplementos': 'Suplementos',
  'datosSalud.hidratacion': 'Hidratación',
  'datosSalud.observaciones': 'Observaciones',

  'datosActividadFisica.actividadPosicion': 'Actividad y posición si aplica',
  'datosActividadFisica.equipo': 'Equipo (si aplica)',
  'datosActividadFisica.numero': 'Número (si aplica)',
  'datosActividadFisica.ejercicio': 'Ejercicio',
  'datosActividadFisica.objetivoDeportivo': 'Objetivo deportivo / Objetivo de la consulta',

  'masaEsqueletica.kilos': 'Kg esqueleto',
  'masaEsqueletica.porcentaje': '% esqueleto',
  'medicion.masaEsqueletica': 'Masa esquelética',
  'medicion.anterior': 'anterior',
  'medicion.actual': 'actual',
  'medicion.fechaMedicion': 'Fecha de medición',
  'medicion.tipoMedicion': 'Tipo de medición',
  'medicion.creadaPor': 'Creada por',
  'medicion.datosBasicos': 'Datos básicos',
  'medicion.seMidioEl': 'Se midió el',
  'medicion.diametros': 'Diámetros',
  'medicion.perimetros': 'Perímetros',
  'medicion.pliegues': 'Pliegues',
  'medicion.acciones': 'Acciones',
  'medicion.formulasCalculo': 'Fórmulas de cálculo de presentación',
  'medicion.somatotipo': 'Somatotipo',
  'medicion.indiceMusculoOseo': 'Índice músculo-óseo',
  'medicion.indices': 'Índices',
  'medicion.5Componentes': '5 componentes',
  'medicion.fraccionamientoEn4Componentes': 'Fraccionamiento en 4 componentes',
  'medicion.estimacionDelDescencoDeGrasa': 'Estimación del descenco de grasa',
  'medicion.ecuaciones': 'Ecuaciones',
  'medicion.sumatoriasDePliegues': 'Sumatorias de pliegues',
  'medicion.masas': 'Masas',
  'medicion.appointment': 'Siguiente cita',

  '3 pliegues para Lohmann': '3 pliegues for Lohmann',

  '3 Pliegues (Triceps)': '3 pliegues (Triceps)',

  '3 Pliegues (Abdominal)': '3 pliegues (Abdominal)',

  '4 Pliegues': '4 pliegues',

  '6 Pliegues': '6 pliegues',

  '8 Pliegues': '8 pliegues',

  'medicion.estimacionDelDescensoDeGrasa': 'Estimación del descenso de grasa',
  'medicion.estimacionDelIncrementoDeMusculo': 'Estimación del aumento de músculo',
  'medicion.resumenCalculo': 'Resumen de cálculo',
  'medicion.crearNuevaMedicion': 'Crear una nueva medición',
  'medicion.medicionesAnteriores': 'Hay mediciones anteriores',
  'medicion.medicionesAnterioresMensaje':
    'Si lo deseas puedes copiar todos los valores de una sesión anterior, y sólo modificar los que necesites',

  'fechaMedicion.fecha': 'Fecha',
  'tipoMedicion.numeroComponentes': 'Número de componentes',
  'datosBasicos.peso': 'Peso',
  'datosBasicos.alturaDePie': 'Altura de pie',
  'datosBasicos.alturaSentado': 'Altura sentado',
  'diametros.envergadura': 'Envergadura',
  'diametros.biacromial': 'Biacromial',
  'diametros.toraxTransverso': 'Tórax transverso',
  'diametros.toraxAnteroPosterior': 'Tórax antero posterior',
  'diametros.biiliocrestideo': 'Bi-iliocrestideo',
  'diametros.humeralBiepicondilar': 'Humeral biepicondilar',
  'diametros.femoralBiepicondilar': 'Femoral biepicondilar',
  'diametros.munecaBioestiloideo': 'Muñeca bioestiloideo',
  'diametros.tobilloBimaleolar': 'Tobillo bimaleolar',
  'diametros.humeral': 'Humeral',
  'diametros.muneca': 'Muñeca',
  'perimetros.cabeza': 'Cabeza',
  'perimetros.brazoRelajado': 'Brazo relajado',
  'perimetros.brazoFlexionado': 'Brazo flexionado',
  'perimetros.antebrazoMaximo': 'Antebrazo máximo',
  'perimetros.muneca': 'Muñeca',
  'perimetros.toraxMesoesternal': 'Tórax mesoesternal',
  'perimetros.cinturaMinima': 'Cintura mínima',
  'perimetros.caderaMaxima': 'Cadera máxima',
  'perimetros.musloMaximo': 'Muslo máximo',
  'perimetros.musloMedio': 'Muslo medio',
  'perimetros.pantorrillaMaxima': 'Pantorrilla máxima',
  'pliegues.triceps': 'Tríceps',
  'pliegues.subescapular': 'Subescapular',
  'pliegues.biceps': 'Bíceps',
  'pliegues.crestaIliaca': 'Cresta iliaca',
  'pliegues.supraespinal': 'Supraespinal',
  'pliegues.abdominal': 'Abdominal',
  'pliegues.musloFrontal': 'Muslo frontal',
  'pliegues.pantorrillaMaxima': 'Pantorrilla máxima',
  'pliegues.pantorrilla': 'Pantorrilla',
  'pliegues.sumatoria': 'Sumatoria de pliegues en milímetros',
  'pliegues.sumatoriaDe6Pliegues': 'Sumatoria de 6 pliegues',
  'pliegues.sumatoriaDe8Pliegues': 'Sumatoria de 8 pliegues',
  'masa.adiposa': 'Masa Adiposa',
  'masa.adiposaPorcentaje': 'Porcentaje de masa adiposa',
  'masa.residual': 'Masa Residual',
  'masa.osea': 'Masa ósea',
  'masa.oseaPorcentaje': 'Porcentaje de masa ósea',
  'masa.muscular': 'Masa muscular',
  'masa.piel': 'Masa piel',
  'masa.muscularPorcentaje': 'Porcentaje de masa muscular',
  'grasa.porcentaje': 'Porcentaje de grasa',
  'formulasCalculo.grasas': 'Grasa corporal',
  'formulasCalculo.musculo': 'Masa muscular',
  'somatotipo.endomorfo': 'Endomorfo',
  'somatotipo.ectomorfo': 'Ectomorfo',
  'somatotipo.mesomorfo': 'Mesomorfo',
  'somatotipo.X': 'Coordenada X',
  'somatotipo.Y': 'Coordenada Y',
  'indiceMusculoOseo.valor': 'Valor',
  'resumenCalculo.edad': 'Edad',
  'resumenCalculo.genero': 'Género',
  'resumenCalculo.porcentajeDeGrasaElegido': 'Porcentaje de grasa elegido',
  'resumenCalculo.porcentajeDeMusculoElegido': 'Porcentaje de músculo elegido',
  'resumenCalculo.masaReajustadaPiel': 'Masa reajustada piel',
  'resumenCalculo.masaReajustadaAdiposa': 'Masa reajustada adiposa',
  'resumenCalculo.masaReajustadaMuscular': 'Masa reajustada muscular',
  'resumenCalculo.masaReajustadaResidual': 'Masa reajustada residual',
  'resumenCalculo.masaReajustadaOsea': 'Masa reajustada ósea',
  'resumenCalculo.indiceAdiposo': 'Índice adiposo',
  'resumenCalculo.indiceMuscular': 'Índice muscular',
  'resumenCalculo.indiceResidual': 'Índice residual',
  'resumenCalculo.indiceOsea': 'Índice ósea',
  'resumenCalculo.indicePiel': 'Índice piel',
  'resumenCalculo.indiceMusculoLastre': 'Índice musculo lastre',
  'resumenCalculo.zScoreAdiposa': 'Z score adiposa',
  'resumenCalculo.zScoreMuscular': 'Z score muscular',
  'resumenCalculo.zScoreResidual': 'Z score residual',
  'resumenCalculo.zScoreCabeza': 'Z score cabeza',
  'resumenCalculo.zScoreOsea': 'Z score ósea',

  'peso.pesoIdeal': 'Peso ideal de referencia en kilogramos',

  'perfilReferencia.nombre': 'Posición / Categoría',
  'perfilReferencia.edad': 'Edad',
  'perfilReferencia.genero': 'Género',
  'perfilReferencia.deporteActividad': 'Deporte/Actividad',
  'perfilReferencia.crear': 'Crea un nuevo perfil de referencia',
  'perfilReferencia.seleccionar': 'Selecciona un perfil de referencia',
  'perfilReferencia.tolerencia': 'Tolerancia de',
  'perfilReferencia.indiceMusculoOseo': 'Indice Músculo Óseo',
  'perfilReferencia.sumatoriaDe6Pliegues': 'Sumatoria de 6 pliegues',
  'placeholder.direccion': 'Address',
  'placeholder.celular': 'Teléfono celular',
  'placeholder.recomendadoPor': 'Escribe el nombre de la persona que lo recomendó',
  'placeholder.relatoAlimentacion': 'Escribe aquí un día de su alimentación',
  'placeholder.problemasSalud': 'Escribe aquí si tiene problemas de salud',
  'placeholder.suplementos': 'Escribe aquí si consume suplementos',
  'placeholder.hidratacion': 'Escribe aquí cómo se hidrata',
  'placeholder.observaciones': 'Escribe aquí alguna observación adicional',
  'placeholder.ejercicio':
    'Describe aquí su actividad física, la frecuencia, intensidad, duración y otros datos que consideres necesarios',
  'placeholder.objetivoDeportivo':
    'Anota la fecha y los diferentes objetivos a trabajar en el próximo periodo',
  'placeholder.nombreGrupo': 'Escriba el nombre del grupo',

  alimento: 'Alimento',
  Kcal: 'Kcal',
  Proteinas: 'Proteinas',
  Carbohidratos: 'Carbohidratos',
  Grasas: 'Grasas',

  'alimento.cereal': 'Cereal',
  'alimento.grasa': 'Grasa',
  'alimento.proteina': 'Proteina',
  'alimento.proteinaOtro': 'Proteina (Otro)',
  'alimento.lácteos': 'Lácteos',
  'alimento.lácteosOtro': 'Lácteos (Otro)',
  'alimento.verdura': 'Verdura',
  'alimento.fruta': 'Fruta',
  'alimento.protEnPolvoConCarb': 'Prot en polvo c/carb',
  'alimento.protEnPolvoSinCarb': 'Prot en polvo s/carb',
  'alimento.bebidaDeportiva': 'Bebida deportiva',
  'alimento.gel': 'Gel',
  'reference.ViewButton': 'Ver',
  'reference.ExamplesEs': 'Equivalencias regionales en español',
  'reference.ExamplesEn': 'Equivalencias regionales en Ingles',

  'buttons.generatePassword': 'Generar contraseña',
  'buttons.sendWelcomeEmail': 'Enviar correo de bienvenida',

  'presentationComponents.evaluacionAntropometrica': 'EVALUACIÓN ANTROPOMÉTRICA',
  'presentationComponents.fechaEvaluacion': 'Fecha de evaluación',
  'presentationComponents.posicion': 'Posición',
  'presentationComponents.grasaCorporal': 'GRASA CORPORAL',
  'presentationComponents.porcentajeGrasa': '% GRASA',
  'presentationComponents.sumatoriaPliegues': 'SUMATORIA DE PLIEGUES (mm)',
  'presentationComponents.sumatoriaPlieguesTabla': 'SUMA PLIEGUES ACTUAL (MM)',
  'presentationComponents.sumatoriaPlieguesReferenciaTabla': 'SUMA PLIEGUES MÁXIMA (MM)',
  'presentationComponents.referencia': 'REFERENCIA (mm)',
  'presentationComponents.referenciaKgs': 'REFERENCIA (KG)',
  'presentationComponents.sumatoriaPlieguesTitulo': 'SUMATORIA DE PLIEGUES',
  'presentationComponents.indiceMusculoOseoTitulo': 'ÍNDICE MÚSCULO ÓSEO',
  'presentationComponents.diametrosTitulo': 'DIÁMETROS',
  'presentationComponents.circunferenciasTitulo': 'CIRCUNFERENCIAS',
  'presentationComponents.plieguesTitulo': 'PLIEGUES',
  'presentationComponents.masaMuscularTitulo': 'MASA MUSCULAR',
  'presentationComponents.masaAdiposa': 'MASA ADIPOSA',
  'presentationComponents.masaOsea': 'Masa ósea',
  'presentationComponents.masaOseaTitulo': 'MASA ÓSEA',
  'presentationComponents.porcentajeMasaOseaTitulo': 'PORCENTAJE DE MASA ÓSEA',
  'presentationComponents.valorPorcentajeMasaOsea': 'Masa ósea',
  'presentationComponents.valorKilosMasaOsea': 'Masa ósea',
  'presentationComponents.sumatoriaPlieguesActual': 'SUMATORIA PLIEGUES ACTUAL (mm)',
  'presentationComponents.sumatoriaPlieguesMaxima': 'SUMATORIA PLIEGUES MÁXIMA (mm)',
  'presentationComponents.masaMuscularKgs': 'MASA MUSCULAR (KG)',
  'presentationComponents.masaMuscularKgsReferencia': 'MASA MUSCULAR (KG) REFERENCIA',
  'presentationComponents.observaciones': 'OBSERVACIONES',
  'presentationComponents.necesitasBajar': 'Necesitas',
  'presentationComponents.necesitasSubir': 'Necesitas',

  'tableHeader.playerName': 'Nombre del jugador',
  'tableHeader.patientName': 'Nombre del paciente',
  'tableHeader.patientEmail': 'Email',
  'tableHeader.sport': 'Deporte',
  'tableHeader.position': 'Posición',
  'tableHeader.cellPhone': 'Celular',
  'tableHeader.days': 'Dias desde la ultima sesión',
  'tableHeader.app': 'Uso de la app',
  'tableHeader.daysClients': 'Dias restantes',
  'tableHeader.actions': 'Acciones',
  'tableHeader.clientName': 'Nombre del cliente',
  'tableHeader.clientEmail': 'Email',
  'tableHeader.serviceEndDate': 'Fecha de fin del servicio',
  'tableHeader.nombrePerfilReferencia': 'Nombre del perfil de referencia',
  'tableHeader.edadPerfilReferencia': 'Edad',
  'tableHeader.generoPerfilReferencia': 'Género',
  'tableHeader.deportePerfilReferencia': 'Deporte/Actividad',
  'tableHeader.fechaActualizacionPerfilReferencia': 'Fecha de actualización',
  'tableHeader.acciones': 'Acciones',
  'tableHeader.archivoReferenciaNombre': 'Nombre del archivo de referencia',
  'tableHeader.archivoNombre': 'Nombre del archivo',
  'tableHeader.visibility': 'Visibilidad',
  'tableHeader.createdAt': 'Fecha de carga',
  'tableHeader.groupName': 'Nombre',
  'tableHeader.membersNumber': '# de Miembros',
  'tableHeader.groupActions': 'Acciones',
  'tableHeader.number': 'Número de playera',
  'tableHeader.email': 'Correo',

  'topBar.issuesHistory': 'Historial de Issues',
  'topBar.projectManagement': 'Administración de proyectos',
  'topBar.typeToSearch': 'Escribe para buscar...',
  'topBar.buyNow': 'Comprar por $24',
  'topBar.bitcoin': 'Bitcoin',
  'topBar.profileMenu.hello': 'Hola',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Rol',
  'topBar.profileMenu.email': 'Email',
  'topBar.profileMenu.phone': 'Teléfono',
  'topBar.profileMenu.editProfile': 'Editar perfil',
  'topBar.profileMenu.editPassword': 'Editar contraseña',
  'topBar.profileMenu.logout': 'Salir',

  'value.sumatoriaPliegues': 'Sumatoria de pliegues',
  'value.masaAdiposa': 'Masa adiposa',
  'value.porcentajeGrasa': 'Porcentaje de grasa',
  'value.masaMuscular': 'Masa muscular',
  'value.masaMuscularTitle': 'MASA MUSCULAR',
  'value.edad': 'Edad',
  'value.equipo': 'Equipo',
  'value.deportePosicion': 'Deporte y/o posición',
  'value.peso': 'Peso',
  'value.altura': 'Altura',
  'value.actual': 'Actual',
  'value.ideal': 'Ideal',
  'value.maximo': 'Máximo',
  'value.referencia': 'Referencia',
  'value.porcentaje': 'Porcentaje',
  'value.years': 'años',
  'value.of': 'de',
  'value.anos': 'años',

  'filterReport.edad': 'Edad',
  'filterReport.equipo': 'Categoría',
  'filterReport.genero': 'Género',
  'filterReport.posicion': 'Posición',
  'filterReport.medida': 'Variable',
  'filter.male': 'Hombre',
  'filter.female': 'Mujer',
  'filter.delete': 'Eliminar',
  'filter.add': 'Agregar filtro',
  'filter.addAge': 'Agregar edad',
  'filter.addTeam': 'Agregar equipo',
  'filter.addGender': 'Agregar género',
  'filter.addPosition': 'Agregar posición',
  'filter.addVariable': 'Agregar variable',

  'filterReportSelected.edad': 'Edad seleccionada de:',
  'filterReportSelected.equipo': 'Equipo seleccionado:',
  'filterReportSelected.genero': 'Género seleccionado:',
  'filterReportSelected.poscion': 'Posición seleccionada:',
  'filterReportSelected.medida': 'Variable seleccionada',

  'bio.prealbumina': 'Prealbúmina',
  'bio.albumin': 'Albúmina',
  'bio.totalProteins': 'Proteínas totales',
  'bio.urea': 'Urea',
  'bio.totalCholesterol': 'Colesterol total',
  'bio.hdlCholesterol': 'Colesterol HDL',
  'bio.ldlCholesterol': 'Colesterol LDL',
  'bio.triglycerides': 'Triglicéridos',
  'bio.glucose': 'Glucosa sanguinea',
  'bio.sodium': 'Sodio',
  'bio.potassium': 'Potasio',
  'bio.chlorine': 'Cloro',
  'bio.magnesium': 'Magnesio',
  'bio.calcium': 'Calcio',
  'bio.serumIron': 'Hierro sérico',
  'bio.ferritin': 'Ferritina',
  'bio.transferrin': 'Transferrina',
  'bio.hemoglobin': 'Hemoglobina',
  'bio.hematocrito': 'Hematocrito',
  'bio.vcm': 'VCM',
  'bio.leukocytes': 'Leucocitos',
  'bio.lymphocytes': 'Linfocitos',
  'bio.alt': 'ALT',
  'bio.ast': 'AST',
  'bio.creatinine': 'Creatinina',
  'bio.uricAcid': 'Ácido Úrico',
  'bio.totalBilirubin': 'Bilirrubina total',
  'bio.platelets': 'Plaquetas',
  'bio.glomerularFilteringRate': 'Tasa de filtrado glomerular',
  'bio.testosterone': 'Testosterona',
  'bio.cortisolInFasting': 'Cortisol en ayunas',

  'tablaProgresoSection.grasa': 'Grasa corporal',
  'tablaProgresoSection.musculo': 'Masa Muscular',
  'tablaProgresoSection.basicos': 'Básicos',
  'tablaProgresoHeader.masaAdiposaSesion': 'Suma de pliegues actual (mm)',
  'tablaProgresoHeader.masaAdiposaMax': 'Masa adiposa máxima (kg)',
  'tablaProgresoHeader.masaAdiposaActual': 'Masa adiposa actual (kg)',
  'tablaProgresoHeader.masaAdiposaDif': 'Masa adiposa a bajar (kg)',
  'tablaProgresoHeader.masaMusculoSesion': 'Músculo actual (kg)',
  'tablaProgresoHeader.masaMusculoRef': 'Masa muscular referencia (kg)',
  'tablaProgresoHeader.masaMusculoDif': 'Masa muscular a subir (kg)',
  'tablaProgresoHeader.peso': 'Peso (kg)',
  'tablaProgresoHeader.altura': 'Altura (cm)',
  'gridProgressReport.tittle': 'Reporte de progreso',

  'tablaProgresoHeader.Categoria': 'Categoria',
  'tablaProgresoHeader.Grasa': 'Sumatoria de pliegues',
  'tablaProgresoHeader.musculo': 'Masa Muscular',
  'tablaProgresoHeader.basicos': 'Peso y Altura',

  'tablaProgresoSesionHeader.al': 'al',
  'tablaProgresoSesionHeader.de': 'de',
  'tablaProgresoSesionHeader.Nombre': 'Nombre',

  'menuData.FirmaDelUsuario': 'Firma del ususario',
  'menuData.DeleteFirmaDelUsuario': 'Borra firma',
  'menuData.SelectorDeArchivo': 'Examinar...',
  'menuData.DeleteSelectorDeArchivo': 'Eliminar logo',

  'prefilReferencia.medicion': 'Medición',
  'prefilReferencia.valor': 'Valor',
  'prefilReferencia.tolerancia': 'Tolerancia',
  'prefilReferencia.datosBasicos': 'Datos básicos',
  'prefilReferencia.diametros': 'Diámetros',
  'prefilReferencia.perimetros': 'Perímetros',
  'prefilReferencia.pliegues': 'Pliegues',
  'prefilReferencia.otros': 'Otros',
  'prefilReferencia.resultados': 'Resultados',
  'medidas.cm': ' (cm)',
  'medidas.mm': ' (mm)',
  'medidas.kg': ' (kg)',
  'medidas.ecuacion': ' (ecuación)',

  '2componentes.formula': 'Fórmula',
  '2componentes.selectInvest': 'Selecciona un investigador',
  '2componentes.grasaDeseado': 'Porcentaje de grasa deseado',
  '2componentes.disminuir': 'Disminuir',
  '2componentes.6plieguesDeseado': 'Suma 6 pliegues deseado',
  '2componentes.mmDePliegues': 'mm de pliegues',

  'portions.Cereales': 'Cereal: ',
  'portions.Grasas': 'Grasa: ',
  'portions.Proteinas': 'Proteina: ',
  'portions.Lacteos': 'Lacteo: ',
  'portions.Vegetales': 'Vegetales: ',
  'portions.Frutas': 'Fruta: ',

  'reportsStepHeader.1': 'Tipo de reporte',
  'reportsStepHeader.2': 'Selecciona a los pacientes',
  'reportsStepHeader.3': 'Selecciona las medidas a comparar',
  'reportsStepHeader.4': 'Ver resultado',
  'reportsStep.Date': '¿ Cuándo ?',
  'messageConfirm.SelectedDate': 'Fecha seleccionada del:',
  'messageConfirm.At': 'al',
  'ReportHeader.ComparacionGrasa': 'Comparativa de grasa',
  'ReportHeader.ComparacionMuscle': 'Comparativa de músculo',

  'pieReport.optima': 'optima',
  'pieReport.deficiente': 'deficiente',
  'pieReport.excesiva': 'excesiva',
  'pieReport.JugadoresOptimos': 'Optimos',
  'pieReport.JugadoresDeficientes': 'Deficientes',
  'pieReport.JugadoresExcesiva': 'Excesiva',
  'pieReport.JugadoresComparados': 'No. Jugadores comparados',
  'pieReport.JugadoresConGrasa': 'Jugadores con masa grasa',
  'pieReport.JugadoresConMuscular': 'Jugadores con masa muscular',

  'LinesReport.Referencia': 'Referencia',
  'LinesReport.Paciente': 'Paciente',
  'LinesReport.Medida': 'medida',

  'tabPaciente.SelectorMedidas': 'Medidas',
  'tabPaciente.SelectorAntropo': 'antropométricas',

  'buttonPatient.recordatorio': 'Enviar recordatorio de porciones en app',
  'placeHolder.SelectorMes': 'Selecciona un mes',

  'patientSessionIndice.IMC': 'Índice Masa Corporal (IMC)',
  'patientSessionIndice.cintura': 'Índice cintura/caderas',
  'patientSessionIndice.musculo': 'Índice músculo / óseo (con ecuaciones de Martin)',
  'patientSessionIndice.adiposoMuscular': 'Índice adiposo muscular',
  'patientSessionIndice.cormico': 'Índice Córmico (%)',
  'patientSessionIndice.adiposo': 'Índice adiposo',
  'patientSessionIndice.muscular': 'Índice Muscular',
  'patientSessionIndice.residual': 'Índice Residual',
  'patientSessionIndice.oseo': 'Índice Óseo',
  'patientSessionIndice.piel': 'Índice Piel',
  'patientSessionIndice.musculoOseo': 'Índice Musculo Óseo',
  'patientSessionIndice.musculoLastre': 'Índice Musculo Lastre',
  'patientSessionIndice.lastre': 'Índice Lastre',

  'alert.sinPerfil': 'No has agregado un perfil de referencia, agrégalo para continuar',
  'appointment.selectDate': 'Selecciona fecha y hora de la siguiente cita',
  'appointment.selectDuration': 'Selecciona la duracion de la cita',
  'patientConfiguration.activateIntenseworkout': 'Activar dia de entrenamiento intenso',
  'patientConfiguration.activateRestDay': 'Activar dia de descanso',
  'patientConfiguration.intenseworkout': 'Entrenamiento intenso',
  'patientConfiguration.ligtheworkout': 'Entrenamiento ligero',
  'patientConfiguration.restworkout': 'Entrenamiento descanso',
  'patientConfiguration.equivalentButton': 'Configurar equivalentes',

  'fatLossEstimation.talla': 'Talla (cm)',
  'fatLossEstimation.objetivoPliegues': 'Objetivo suma 6 pliegues',
  'fatLossEstimation.zAdiposa': 'Z-adiposa',
  'fatLossEstimation.maxAdipose': 'Masa adiposa máxima Kg',
  'fatLossEstimation.masaAdiposaActual': 'Masa adiposa actual Kg',
  'fatLossEstimation.masaAdiposaDif': 'Masa adiposa a bajar:',

  'muscleMassEstimation.osseousMass': 'Masa ósea Kg',
  'muscleMassEstimation.indiceObjetivo': 'obj. Índice M/O',
  'muscleMassEstimation.muscularMassActual': 'Masa muscular actual Kg',
  'muscleMassEstimation.muscularMassRef': 'Masa muscular referencia',
  'muscleMassEstimation.muscularMassDif': 'Masa muscular a aumentar:',

  'reportType.Tabla': 'Tabla',
  'reportType.Pie': 'Pastel',
  'reportType.Puntos': 'Lineas',

};

export default {
  locale: 'es-LA',
  antdData,
  localeData,
  messages,
};
