import React, { useEffect, useMemo, useState } from 'react';
import Axios from 'axios';
import { Button, message } from 'antd';
import PriceCardYearly from './PriceCardYearly';
import PriceCardMonthly from './PriceCardMonthly';

function Card({
  namePlan, planType, id,
}) {
  const [billingPlans, setBillingPlans] = useState([]);
  const currentRolString = localStorage.getItem('issuer');
  const currentRol = JSON.parse(currentRolString);
  const userID = currentRol.id;
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };

  useEffect(() => {
    Axios.get(`${global.ApiDomain}/plans/billing-plans/${id}`, { headers })
      .then((res) => {
        setBillingPlans(res.data);
      });
  }, []);

  const billingPlan = useMemo(() => {
    return (billingPlans.find((bp) => {
      return bp.frequency === planType;
    }));
  }, [billingPlans, planType]);

  if (!billingPlan) {
    return null;
  }

  const { price } = billingPlan;

  const handleBillingAgreement = () => {
    message.success(`Id: ${billingPlan.paypalId}`);
    Axios.post(`${global.ApiDomain}/create-billing-agreement/`, {
      issuerID: userID,
      paypalID: billingPlan.paypalId,
    }, { headers }).then((response) => {
      console.log(response);
      const { link } = response.data;
      window.open(link, '_top');
    }).catch((e) => {
      console.log(e);
    });
  };
  return (
    <div className="plan__card" style={{ margin: 15 }}>
      <h1>{namePlan}</h1>
      {/* <div className="plan__description">
        <span>{description}</span>
      </div> */}

      {
        planType === 'YEAR' ? <PriceCardYearly price={price} /> : <PriceCardMonthly price={price} />
      }

      <Button
        type="primary"
        className="plan__button"
        onClick={handleBillingAgreement}
      >Continuar
      </Button>
    </div>
  );
}

Card.defaultProps = {
  namePlan: 'Individual',
  description: 'Description...',
};

export default Card;
