import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerGrupos = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
  const grupos = await axios.get(`${global.ApiDomain}/grupos/`, { headers });
  return grupos;
};

export const editarGrupo = (grupo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const grupos = await axios.put(`${global.ApiDomain}/grupos/`, grupo, { headers });
    return grupos;
  };
};

export const crearGrupo = (grupo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const grupos = await axios.post(`${global.ApiDomain}/grupos/`, grupo, { headers });
    return grupos;
  };
};

export const eliminarGrupo = (grupo) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const grupos = await axios.delete(`${global.ApiDomain}/grupos/${grupo.id}`, { headers });
    return grupos;
  };
};
