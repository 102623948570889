/* eslint-disable no-debugger */
import {
  all, takeEvery, put, call,
} from 'redux-saga/effects';
import { message } from 'antd';
import {
  obtenerPacientes, editarPaciente, crearPaciente, eliminarPaciente,
} from 'services/pacientes';
import _ from 'lodash';
import actions from './actions';

export function* LOAD_PACIENTES() {
  yield put({
    type: 'pacientes/SET_STATE',
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(obtenerPacientes);

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'pacientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });

      // yield put({
      //   type: 'user/SET_STATE',
      //   payload: {
      //     loading: false,
      //   },
      // })
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export function* EDITAR_PACIENTE(paciente) {
  try {
    const response = yield call(editarPaciente(paciente.payload));

    if (response) {
      const { data: entities } = response;

      message.destroy();
      message.success('Guardado correctamente');

      yield put({
        type: 'pacientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* CREAR_PACIENTE(paciente) {
  try {
    const response = yield call(crearPaciente(paciente.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'pacientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
      const newPatientId = entities
        .find((pac) => {
          return _.get(pac, 'nombre', 'x') === _.get(paciente, 'payload.nombre', 'z') && _.get(pac, 'email', 'x') === _.get(paciente, 'payload.email', 'z');
        }).id;
      window.open(`/paciente/${newPatientId}/data`, '_top');
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* ELIMINAR_PACIENTE(paciente) {
  try {
    const response = yield call(eliminarPaciente(paciente.payload));

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'pacientes/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}


export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PACIENTES, LOAD_PACIENTES),
    takeEvery(actions.EDITAR_PACIENTE, EDITAR_PACIENTE),
    takeEvery(actions.CREAR_PACIENTE, CREAR_PACIENTE),
    takeEvery(actions.ELIMINAR_PACIENTE, ELIMINAR_PACIENTE),
    LOAD_PACIENTES(), // run once on app load to check user auth
  ]);
}
