/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { message } from 'antd';
import { obtenerSesions, editarSesion, crearSesion, eliminarSesion } from 'services/sesions';
import _ from 'lodash';

import actions from './actions';

export function* LOAD_SESIONS({ payload: { pacienteId } }) {
  console.log('function*LOAD_SESIONS -> pacienteId', pacienteId);
  yield put({
    type: 'sesiones/SET_STATE',
    payload: {
      loading: true,
      entities: [],
    },
  });

  try {
    const response = yield call(obtenerSesions);

    if (response) {
      const { data: entities } = response;
      yield put({
        type: 'sesiones/SET_STATE',
        payload: {
          loading: false,
          entities,
        },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* EDITAR_SESION(sesion) {
  try {
    const response = yield call(editarSesion(sesion.payload));

    if (response) {
      // window.location.reload();

      message.success('Se guardó correctamente');

      const { pacienteId } = sesion.payload;

      yield put({
        type: actions.LOAD_SESIONS,
        payload: { pacienteId },
      });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* CREAR_SESION(sesion) {
  try {
    const [response, pacienteId] = yield call(crearSesion(sesion.payload));

    if (response) {
      const sesionId = _.get(response, 'data[0].id', false);
      if (!sesionId) {
        message.warning('No se pudo crear la sesión');
        return;
      }

      const url = `/paciente/${pacienteId}/sesion/${sesionId}`;
      window.location.href = url;

      // yield put({
      //   type: actions.LOAD_SESIONS,
      //   payload: { pacienteId },
      // });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export function* ELIMINAR_SESION(sesion) {
  try {
    const response = yield call(eliminarSesion(sesion.payload));

    if (response) {
      window.location.reload();
      // const { pacienteId } = sesion.payload;

      // yield put({
      //   type: actions.LOAD_SESIONS,
      //   payload: { pacienteId },
      // });
    }
  } catch (error) {
    message.warning(error.toString());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SESIONS, LOAD_SESIONS),
    takeEvery(actions.EDITAR_SESION, EDITAR_SESION),
    takeEvery(actions.CREAR_SESION, CREAR_SESION),
    takeEvery(actions.ELIMINAR_SESION, ELIMINAR_SESION),
    // LOAD_SESIONS(), // run once on app load to check user auth
  ]);
}
