const actions = {
  SET_STATE: 'bitacoras/SET_STATE',
  LOAD_BITACORAS: 'bitacoras/LOAD_BITACORAS',
  EDITAR_BITACORA: 'bitacoras/EDITAR_BITACORA',
  CREAR_BITACORA: 'bitacoras/CREAR_BITACORA',
  ELIMINAR_BITACORA: 'bitacoras/ELIMINAR_BITACORA',
  SET_SELECTED_BITACORA: 'bitacoras/SET_SELECTED_BITACORA',
};

export default actions;
