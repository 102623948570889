import React, { useEffect, useState } from 'react';
// import HomeMenu from './HomeMenu';
// import ProjectManagement from './ProjectManagement'
// import IssuesHistory from './IssuesHistory'
// import LiveSearch from './LiveSearch';
import { Alert } from 'antd';
import { useSelector } from 'react-redux';
import ProfileMenu from './ProfileMenu';
import LanguageSelector from './LanguageSelector';
import styles from './style.module.scss';

const TopBar = () => {
  const [open, setOpen] = useState(false);
  const vigente = useSelector((state) => { return state.user.vigente; });

  useEffect(() => {
    setOpen(!vigente);
  }, [vigente]);

  return (
    <div className={styles.topbar}>
      {/* <div className="mr-4">
          <IssuesHistory />
        </div>
        <div className="mr-4">
          <ProjectManagement />
        </div> */}
      <div className="ml-auto">
        {/* <LiveSearch /> */}
      </div>

      <div className="mr-4">
        {open && (
          <Alert message="Suscripcion caducada" type="warning" closable />
        )}
      </div>
      <div className="mr-4">
        <LanguageSelector />
      </div>
      <div className="mr-4">
        {/* <HomeMenu /> */}
      </div>
      <ProfileMenu />
    </div>
  );
};

export default TopBar;
