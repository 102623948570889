import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import reducers from 'redux/reducers';
import sagas from 'redux/sagas';
import Localization from 'components/LayoutComponents/Localization';
import WhatsAppWidget from 'react-whatsapp-widget';
import Router from './router';
import * as serviceWorker from './serviceWorker';
import 'react-whatsapp-widget/dist/index.css';

// app styles
import './global.scss';

global.ApiDomain = 'https://api.sistema-mp.app';
global.Version = 'v1.60.0';
// global.ApiDomain = 'http://localhost:1323';

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routeMiddleware = routerMiddleware(history);
const middlewares = [thunk, sagaMiddleware, routeMiddleware];
if (process.env.NODE_ENV === 'development-log') {
  middlewares.push(logger);
}
// // @ts-ignore
// // eslint-disable-next-line no-underscore-dangle
// if (window.__REDUX_DEVTOOLS_EXTENSION__) {
//   // @ts-ignore
//   // eslint-disable-next-line no-underscore-dangle
//   middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__);
// }
const store = createStore(reducers(history), composeEnhancers(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas);

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
    </Localization>

    {/*
      En el archivo global.scss
      en la linea 181 hay un estilo
      para cambiar el color de texto
      del input
    */}
    <WhatsAppWidget
      textReplyTime="Online"
      companyName="Sistema MP"
      sendButton="Enviar"
      placeholder="Escribe tu mensaje"
      message="Hola, estoy en linea, ¿podemos ayudarte?"
      phoneNumber="+5213334402599"
    />
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
export { store, history };
