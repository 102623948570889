const actions = {
  SET_STATE: 'grupos/SET_STATE',
  LOAD_GRUPOS: 'grupos/LOAD_GRUPOS',
  EDITAR_GRUPO: 'grupos/EDITAR_GRUPO',
  CREAR_GRUPO: 'grupos/CREAR_GRUPO',
  ELIMINAR_GRUPO: 'grupos/ELIMINAR_GRUPO',
};

// dispatch({
//   type: 'grupos/EDITAR_GRUPO',
//   payload: { id: groupId, nombre: '', miembros: ['id1', 'id2'] },
// });

export default actions;
