import React from 'react';
import { LocaleProvider } from 'antd';
import { IntlProvider, addLocaleData } from 'react-intl';
import { connect } from 'react-redux';

import english from 'locales/en-US';
import spanish from 'locales/es-LA';

import * as moment from 'moment';

moment.locale('es');

addLocaleData(english.localeData);
addLocaleData(spanish.localeData);

const locales = {
  'es-LA': spanish,
  'en-US': english,
};

@connect(({ settings }) => { return { settings }; })
class Localization extends React.Component {
  render() {
    const {
      children,
      settings: { locale },
    } = this.props;
    const currentLocale = locales[locale];
    return (
      <LocaleProvider locale={currentLocale.antdData}>
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
          {children}
        </IntlProvider>
      </LocaleProvider>
    );
  }
}

export default Localization;
