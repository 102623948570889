import React, { useEffect, useState } from 'react';
import { Modal, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import BillingPlans from 'pages/billingPlans';
import './styles.scss';

const { Title } = Typography;

export default function ModalBilling() {
  const [open, setOpen] = useState(false);
  const vigente = useSelector((state) => {
    return state.user.vigente;
  });

  const dispatch = useDispatch();

  const logOut = () => {
    dispatch({
      type: 'user/LOGOUT',
    });
  };

  useEffect(() => {
    setOpen(!vigente);

    if (vigente === false) {
      setInterval(() => {
        setOpen(true);
      }, 5 * 60 * 1000);
    }
  }, [vigente]);

  if (!open) return null;

  return (
    <Modal
      destroyOnClose
      visible={open}
      footer={null}
      width="80%"
      title="Gracias por probar Sistema MP"
      centered
      maskClosable={false}
      mask
      maskStyle={{
        backgroundColor: 'rgba(120, 120, 120, 0.5)',
      }}
      closable
      onCancel={() => {
        logOut();
      }}
      // onOk={() => { setOpen(false); }}
    >
      <Title level={4}>Tu suscripción ha caducado</Title>
      {/* <Paragraph>
        Te contactaremos en el transcurso del día,
        por lo pronto podrás seguir usando el sistema en este lapso de tiempo.
      </Paragraph> */}
      <BillingPlans />
    </Modal>
  );
}
