import axios from 'axios';


// eslint-disable-next-line import/prefer-default-export
export const obtenerArchivosDeReferencia = (patientId) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const archivoDeReferencias = await axios.get(`${global.ApiDomain}/archivos-de-referencia/${patientId}`, { headers });
    return archivoDeReferencias;
  };
};

export const editarArchivoDeReferencia = (archivoDeReferencia) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const archivoDeReferencias = await axios.put(`${global.ApiDomain}/archivos-de-referencia/`, archivoDeReferencia, { headers });
    return archivoDeReferencias;
  };
};

export const crearArchivoDeReferencia = (archivoDeReferencia, patientId) => {
  return async () => {
    const archivoDeReferenciaConPacienteId = {
      ...archivoDeReferencia,
      patientId,
    };
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const archivoDeReferencias = await axios.post(`${global.ApiDomain}/archivos-de-referencia/`, archivoDeReferenciaConPacienteId, { headers });
    return archivoDeReferencias;
  };
};

export const eliminarArchivoDeReferencia = (archivoDeReferencia) => {
  return async () => {
    const headers = { Authorization: `Bearer ${localStorage.getItem('token')}` };
    const archivoDeReferencias = await axios.delete(`${global.ApiDomain}/archivos-de-referencia/remove-file/${archivoDeReferencia.id}`, { headers });
    return archivoDeReferencias;
  };
};
