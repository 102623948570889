/* eslint-disable no-debugger */
import { all, takeEvery, put, call } from 'redux-saga/effects';
import { message } from 'antd';
import { login, currentAccount, logout } from 'services/user';
import actions from './actions';

export function* LOGIN({ payload }) {
  const { email, password } = payload;
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  const success = yield call(login, email, password);
  if (success) {
    message.success('Bienvenido');
    yield put({
      type: 'user/LOAD_CURRENT_ACCOUNT',
    });
  }
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  });

  try {
    const response = yield call(currentAccount);

    if (response) {
      const { usuario, rol, vigente, issuer } = response;
      yield put({
        type: 'user/SET_STATE',
        payload: {
          id: usuario.id,
          name: usuario.nombre,
          email: usuario.email,
          role: rol,
          authorized: true,
          vigente,
          billingPlan: issuer.plan,
          ...response,
        },
      });

      localStorage.setItem('rol', rol);
      localStorage.setItem('vigente', vigente);
      localStorage.setItem('issuer', JSON.stringify(issuer));

      if (rol === 'interesado') {
        const style = document.createElement('style');
        style.type = 'text/css';
        style.id = '1nt3r3s4d0s';

        style.innerHTML = 'button { display: none !important; } .ant-radio-wrapper, input { pointer-events: none !important; } table.data-grid { pointer-events: none; } .ant-form-item-control.has-success { pointer-events: none !important; }';
        document.getElementsByTagName('head')[0].appendChild(style);
      }

      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
        },
      });
      yield put({
        type: 'RELOAD_MENU',
      });
    }
  } catch (error) {
    message.warning('Tu sesión caducó, inicia sesión de nuevo');

    yield put({
      type: actions.LOGOUT,
    });
  }
}

export function* LOGOUT() {
  localStorage.setItem('selectedPatients', '{}');
  localStorage.setItem('selectedMeasure', '{}');
  localStorage.setItem('currentStep', '0');
  localStorage.setItem('filters', '[]');
  localStorage.setItem('selectedDatePie', '[]');
  localStorage.setItem('selectedDatePie2', '[]');
  localStorage.setItem('app.menu.selectedKeys', '["inicio"]');
  localStorage.setItem('rol', '');
  localStorage.setItem('vigente', '');
  localStorage.setItem('issuer', '{}');

  yield call(logout);
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  });

  document.location.reload();
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ]);
}
